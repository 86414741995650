import React, { Component, Fragment } from "react";
import Slider from "react-slick";
import { slideSlick } from "../../page-demo/script";
import Helmet from "../../component/common/Helmet";

const SlideList = [
    {
        textPosition: 'text-left',
        bgImage: 'bg_image--6',
        buttonText: 'Launch',
        buttonLink: '/'
    },
]

class HomePage extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {

        return (
            <Fragment>
                <Helmet pageTitle="Inauguration Page" />
                {/* Start Slider Area   */}
                <div className="slider-wrapper ">
                    <div className="slider-activation">
                        {SlideList.map((value, index) => (
                            <>

                                <div className={`slide slide-style-2 d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} style={{ height: '100%' }} key={index}>
                                    <div className="container pb-0">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className={`inner ${value.textPosition}`}>
                                                    <p className="description font-weight-bold pb-3" style={{ fontSize: '62px', color: '#fff' }}></p>
                                                    <p className="description font-weight-bold" style={{ fontSize: '30px', color: '#fff', paddingBottom: 320 }}><u></u></p>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {value.buttonText ? <div className="slide-btn text-left"><a className="btn-default" style={{ position: 'absolute', bottom: 25, left: 110 }} href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                            </>
                        ))}

                    </div>
                </div>
                {/* End Slider Area   */}

            </Fragment>
        )
    }
}
export default HomePage;