import React, { Component } from "react";
import { FiCheck } from "react-icons/fi";
import PageHelmet from "../../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/FooterTwo";
import Breadcrumb from "../../elements/common/Breadcrumb";


class ServiceDetails extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false,
            isOpen1: false
        }
        this.openModal = this.openModal.bind(this)
        this.openModal1 = this.openModal1.bind(this)
    }

    openModal() {
        this.setState({ isOpen: true })
    }

    openModal1() {
        this.setState({ isOpen1: true })
    }

    render() {
        return (
            <React.Fragment>

                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Synergetic Activities' />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Synergetic Activities'} />
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--3">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row row--35 sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/service-01.png" alt="Service Images" />
                                                    <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='fe3xs_aK1DA' onClose={() => this.setState({ isOpen: false })} />
                                                    <button className="video-popup" onClick={this.openModal}><span className="play-icon"></span></button>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <div className="section-title">
                                                        <h2 className="title">The Comfort Climate Box (CCB)</h2>
                                                        <span className="title" style={{ fontSize: 18, fontWeight: 'bold', color: '#26c0e4' }}>Goals:</span>
                                                        <p className="description">Accelerating the market development for smart integrated heating, cooling and energy storage.</p>
                                                        <span className="title" style={{ fontSize: 18, fontWeight: 'bold', color: '#26c0e4' }}>CCB addresses several issues:</span>
                                                        <ul className="liststyle mt--3">
                                                            <li>Balancing & controlling electricity loads;</li>
                                                            <li>Capturing a larger share of renewable electricity</li>
                                                            <li>Reducing OPEX & emissions greater than 25% of existing</li>
                                                            <li>Overcoming the brand image of the “heat pump”</li>
                                                        </ul>
                                                        <span className="title" style={{ fontSize: 18, fontWeight: 'bold', color: '#26c0e4' }}>Timeline:</span>
                                                        <p className="description">Jan 2019 June 2021(30 month)</p>
                                                        <span className="title" style={{ fontSize: 18, fontWeight: 'bold', color: '#26c0e4' }}>Collaboration:</span>
                                                        <p className="description">IEA TCP HPT & ECES 10 MI member country stakeholders</p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row row--35 sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <div className="section-title">
                                                        <h2 className="title">Predictive Maintenance and Optimisation (PMO)</h2>
                                                        <span className="title" style={{ fontSize: 18, fontWeight: 'bold', color: '#26c0e4' }}>Goals:</span>
                                                        <p className="description">To accelerate the market development of next generation digital technology to drive innovation in advanced building management and energy efficiency. </p>
                                                        <span className="title" style={{ fontSize: 18, fontWeight: 'bold', color: '#26c0e4' }}>PMO addresses several issues:</span>
                                                        <ul className="liststyle mt--3">
                                                            <li>Buildings are poorly maintained, degraded and not well controlled</li>
                                                            <li>Buildings have lots of data</li>
                                                            <li>Digital technology can deliver 15% to 30% savings</li>
                                                        </ul>
                                                        <span className="title" style={{ fontSize: 18, fontWeight: 'bold', color: '#26c0e4' }}>Timeline:</span>
                                                        <p className="description">Jan 2020 - Dec 2021(24 months)</p>
                                                        <span className="title" style={{ fontSize: 18, fontWeight: 'bold', color: '#26c0e4' }}>Collaboration:</span>
                                                        <p className="description">IEA  Buildings and Communities Programme TCP, 13 MI members + 50 external stakeholders</p>

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-12">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/service-02.png" alt="Service Images" />
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--3">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row row--35 sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/portfolio/portfolio-6.jpg" alt="Service Images" />
                                                    <ModalVideo channel='youtube' isOpen={this.state.isOpen1} videoId='3_IwemPA2M4' onClose={() => this.setState({ isOpen1: false })} />
                                                    <button className="video-popup" onClick={this.openModal1}><span className="play-icon"></span></button>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <div className="section-title">
                                                        <h2 className="title">The Global Cooling Prize (GCP)</h2>
                                                        <span className="title" style={{ fontSize: 18, fontWeight: 'bold', color: '#26c0e4' }}>Goals:</span>
                                                        <p className="description">To develop a climate-friendly residential cooling solution that can provide access to cooling to people around the world without warming the planet.</p>
                                                        <span className="title" style={{ fontSize: 18, fontWeight: 'bold', color: '#26c0e4' }}>GCP addresses several issues:</span>
                                                        <ul className="liststyle mt--3">
                                                            <li>~4.5 B Room Air Conditioners by 2050 (compared to 1.2 B today)</li>
                                                            <li>Currently very low efficiency</li>
                                                            <li>GCP to develop new technology with 5x less impact</li>
                                                            <li>Potential to mitigate up to 0.5oC of global warming by 2100</li>
                                                        </ul>
                                                        <span className="title" style={{ fontSize: 18, fontWeight: 'bold', color: '#26c0e4' }}>Timeline:</span>
                                                        <p className="description">Nov 2018 - Apr 2021 (28 months)</p>
                                                        <span className="title" style={{ fontSize: 18, fontWeight: 'bold', color: '#26c0e4' }}>Collaboration:</span>
                                                        <p className="description">Partnered by The Rocky Mountain Institute and Indian Govt (DST). 8 Institutions/Industry stakeholders</p>
                                                        <span className="title" style={{ fontSize: 18, fontWeight: 'bold', color: '#26c0e4' }}>Winners:</span>
                                                        <p className="description">Gree Electric Appliances, Inc. of Zhuhai with partner Tsinghua University; and Daikin with partner Nikken Sekkei Ltd. emerged as the two winners among eight Finalists after shattering the perceived ceiling of performance.</p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row row--35 sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <div className="section-title">
                                                        <h2 className="title">India-UK Energy Demand Reduction in theBuilt Environment</h2>
                                                        <span className="title" style={{ fontSize: 18, fontWeight: 'bold', color: '#26c0e4' }}>Goals:</span>
                                                        <p className="description">Reducing Energy Demand and making clean energy more affordable. </p>
                                                        <span className="title" style={{ fontSize: 18, fontWeight: 'bold', color: '#26c0e4' }}>The program consist of 4 projects:</span>
                                                        <ul className="liststyle mt--3">
                                                            <li>Residential building energy demand reduction in India(RESIDE)</li>
                                                            <li>Integrated Urban Model for Built Environment Energy Research (iNUMBER)</li>
                                                            <li>Zero Peak Energy Building Design for India (ZED-i)</li>
                                                            <li>Community-scale Energy Demand Reduction in India (CEDRI)</li>
                                                        </ul>
                                                        <span className="title" style={{ fontSize: 18, fontWeight: 'bold', color: '#26c0e4' }}>Timeline:</span>
                                                        <p className="description">2017 -  2022</p>
                                                        <span className="title" style={{ fontSize: 18, fontWeight: 'bold', color: '#26c0e4' }}>Collaboration:</span>
                                                        <p className="description">Partnered by Department of Science and Technology, India & Engineering and Physical Sciences Research Council, UK; 20 R&D institutions+Industry stakeholders.</p>

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-12">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/service-03.jpg" alt="Service Images" />
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}


                {/* Start Page Wrapper */}
                {/* <div className="rn-service-details ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner"> */}
                {/* Start Single Area */}
                {/* <div className="row row--35 sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="details">
                                                    <div className="section-title">
                                                        <span className="subtitle">Our service</span>
                                                        <h2 className="title">Providing Service</h2>
                                                        <p className="description">But the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                                                        <p className="description">If you are going to use a passage of Lorem Ipsum. You need to be sure there isn't anything embarrassing If you are going to use a passage of Lorem Ipsum.</p>
                                                    </div>
                                                    <ul className="list-style--1 mt--30">
                                                        <li><FiCheck /> 5 PPC Campaigns the majority.</li>
                                                        <li><FiCheck /> Digital Marketing going to.</li>
                                                        <li><FiCheck /> Marketing Agency passage of.</li>
                                                        <li><FiCheck /> Seo Friendly you are going.</li>
                                                        <li><FiCheck /> 5 PPC Campaigns the majority.</li>
                                                        <li><FiCheck /> 5 PPC Campaigns the majority.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 mt_md--30 mt_sm--30">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/service-02.png" alt="Service Images" />
                                                </div>
                                            </div>
                                        </div> */}
                {/* End Single Area */}
                {/* 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End Page Wrapper */}


                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default ServiceDetails;