import React, { Component } from "react";
import { FiCheck } from "react-icons/fi";
import PageHelmet from "../../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/FooterTwo";
import Breadcrumb from "../../elements/common/Breadcrumb";


class ServiceDetails extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false,
            isOpen1: false
        }
        this.openModal = this.openModal.bind(this)
        this.openModal1 = this.openModal1.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    openModal1() {
        this.setState({ isOpen1: true })
    }
    render() {
        return (
            <React.Fragment>

                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='News Updates' />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'News Updates'} />
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--3">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row row--35 sercice-details-content align-items-center">
                                            <div className="col-lg-12 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <div className="section-title">
                                                        <h2 className="title">Launch of Urban transitions Mission, Nov 2021:</h2>
                                                        <img className="w-100 my-3" src="/assets/images/bg/bg-image-31.jpg" alt="Service Images" />
                                                        <p className="description">The Urban Transitions Mission was launched by the Global Covenant of Mayors for Climate & Energy(GCoM),the European commission,Joint Programming Initiative (JPI) Urban Europe and Mission Innovation on 9 November 2021 at COP26, Glasgow, Scotland. The mission aims to help 50 pilot cities to deliver innovative large-scale clean energy projects. By 2030, the mission will deliver at least 50 large scale integrated demonstration projects in urban environments around the world, providing a pathway for all cities to adopt net zero carbon solutions as the default option. </p>
                                                        <p className="description">The Innovation community on Affordable Heating and Cooling of Buildings is a core mission member. The innovation community were involved in the launch of the mission and presented on the building blocks of successful clean energy urban mission and how the innovation community can feed in to o heating & Cooling blocks. </p>
                                                    </div>

                                                    <div className="about-button mt--50">
                                                        <a className="rn-button-style--2 btn-solid" href="https://www.globalcovenantofmayors.org/press/urban-transitions-mission-launch/" target="_blank">Know More</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row row--35 sercice-details-content align-items-center">
                                            <div className="col-lg-12 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <div className="section-title">
                                                        <h2 className="title">Launch of ‘Beating the Heat’ Sustainable Cooling handbook for Cities, Nov 2021:</h2>
                                                        <p className="description">Beating the Heat: A Sustainable Cooling Handbook for Cities was launched on November 3rd 2021 at COP26 by the Cool Coalition, UNEP, RMI, Global Covenant of Mayors for Climate & Energy (GCoM), Mission Innovation and Clean Cooling Collaborative. </p>
                                                        <p className="description">The new guide offers planners an encyclopaedia of proven options to help cool cities. The guide’s 80 supporting case studies and examples demonstrate the effectiveness of the strategies outlined and can help cities find an approach best suited to their unique contexts. </p>
                                                    </div>

                                                    <div className="about-button mt--20 mb--30">
                                                        <a className="rn-button-style--2 btn-solid" href="https://www.unep.org/resources/report/beating-heat-sustainable-cooling-handbook-cities" target="_blank">Know More</a>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-12 col-12 mt-4">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/bg/bg-image-32.jpg" alt="Service Images" />
                                                    <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='OqMpbL1dnX0' onClose={() => this.setState({ isOpen: false })} />
                                                    <button className="video-popup" onClick={this.openModal}><span className="play-icon"></span></button>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--3">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row row--35 sercice-details-content align-items-center">
                                            <div className="col-lg-12 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <div className="section-title">
                                                        <h2 className="title">Launch of the Innovation Community, Jun 2021:</h2>
                                                        <img className="w-100 my-3" src="/assets/images/bg/bg-image-33.jpg" alt="Service Images" />
                                                        <p className="description">Mission Innovation 2.0 and a decade of clean energy innovation to accelerate achieving the Paris Agreement goals launched at the Innovating to Net Zero Summit, hosted by Chile on 2 June, 2021. The Innovation Community on Affordable Heating and Cooling of Buildings was launched on this day under the Collaborate Module of Innovation Platform. Collaborate Initiatives bring together policy-makers, funders, innovators and international organisations around topics of shared interest to support knowledge-sharing, a common understanding of global RD&D priorities and to identify collaboration opportunities. </p>
                                                    </div>

                                                    <div className="about-button mt--50">
                                                        <a className="rn-button-style--2 btn-solid" href="http://mission-innovation.net/wp-content/uploads/2019/01/Heating-cooling-IC-Nov-2017-workshop-summary.pdf" target="_blank">Know More</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row row--35 sercice-details-content align-items-center">
                                            <div className="col-lg-12 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <div className="section-title">
                                                        <h2 className="title">Launch of IEA’s Task 65 Solar Cooling for the Sunbelt Regions, Sept 2020:</h2>
                                                        <img className="w-100 my-3" src="/assets/images/bg/bg-image-30.jpg" alt="Service Images" />
                                                        <p className="description">The Task aims at covering the small to large size segment of cooling and air conditioning (between 2 kW and 5,000 kW). Both technologies, solar thermal and PV, can be integrated to support a HVAC system accordingly and both systems can be competitive against reference systems when they are well designed, and boundary conditions are favourable. </p>
                                                    </div>
                                                    <div className="about-button mt--50">
                                                        <a className="rn-button-style--2 btn-solid" href="https://task65.iea-shc.org/about" target="_blank">Know More</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--3">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row row--35 sercice-details-content align-items-center">
                                            <div className="col-lg-12 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <div className="section-title">
                                                        <h2 className="title">Deep dive workshop at New Delhi, Nov 2019:</h2>
                                                        <img className="w-100 my-3" src="/assets/images/bg/bg-image-29.jpg" alt="Service Images" />
                                                        <p className="description">The international deep dive workshop on “Low carbon, Affordable Heating and Cooling of Buildings” Focus Area: Thermal Comfort, New Delhi, 6th November 2019. </p>
                                                        <p className="description">The workshop was aimed to facilitate face-to-face meeting, technical interaction and networking in IC7 PAs with specific focus on Thermal Comfort. The workshop took cognizance of the synergies between Thermal Comfort, Predictive Maintenance & Optimization and Building Integration. </p>
                                                    </div>
                                                    <div className="about-button mt--50">
                                                        <a className="rn-button-style--2 btn-solid" href="http://mission-innovation.net/wp-content/uploads/2020/04/Nov-6-Mission-Innovation-IC7-Workshop-Report-Final.pdf" target="_blank">Know More</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row row--35 sercice-details-content align-items-center">
                                            <div className="col-lg-12 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <div className="section-title">
                                                        <h2 className="title">First IC7 workshop at Abu Dhabi, Nov 2017:</h2>
                                                        <img className="w-100 my-3" src="/assets/images/bg/bg-image-28.jpg" alt="Service Images" />
                                                        {/* <img className="w-100 my-3" src="/assets/images/bg/bg-image-29.jpg" alt="Service Images" /> */}
                                                        <p className="description">In November 2017, IC7 held an Expert Workshop in Abu Dhabi, United Arab Emirates. The summary of this workshop defines the work-streams and priority areas under IC7 and has spurred the results described in this page. </p>
                                                        <p className="description">Workshop Summary concisely reports on the technical findings of the Mission Innovation – Innovation Challenge 7 (IC7) Workshop which was held in Abu Dhabi (UAE) on 1 -2 Nov 2017. The Workshop brought together 65 experts from 13 Mission Innovation Members, the Rocky Mountain Institute and the International Renewable Energy Agency (IRENA). The Workshop first explored five priority areas and the main challenges and actions required for each of them: </p>
                                                        <ul className="liststyle mt--3">
                                                            <li>Thermal Energy Storage</li>
                                                            <li>Heat Pumps</li>
                                                            <li>Non-Atmospheric Heat Sinks and Sources</li>
                                                            <li>Predictive Maintenance and Control Optimization  </li>
                                                            <li>Physiological Studies for Thermal Comfort  </li>
                                                        </ul>
                                                        <p className="description">Following this activity the Workshop considered building level integration. The experts identified and discussed a number of cross-cutting issues judged relevant for the IC7 developments: </p>
                                                        <ul className="liststyle mt--3">
                                                            <li>Big and open data platform & build and operational standards</li>
                                                            <li>Dynamic controls </li>
                                                            <li>Non-air-conditioned buildings  </li>
                                                            <li>Heat system integration/ prosumer networks - Climate box</li>
                                                            <li>Non-technological issues </li>
                                                        </ul>
                                                    </div>
                                                    <div className="about-button mt--50">
                                                        <a className="rn-button-style--2 btn-solid" href="http://mission-innovation.net/wp-content/uploads/2019/01/Heating-cooling-IC-Nov-2017-workshop-summary.pdf" target="_blank">Know More</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}


                {/* Start Page Wrapper */}
                {/* <div className="rn-service-details ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner"> */}
                {/* Start Single Area */}
                {/* <div className="row row--35 sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="details">
                                                    <div className="section-title">
                                                        <span className="subtitle">Our service</span>
                                                        <h2 className="title">Providing Service</h2>
                                                        <p className="description">But the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                                                        <p className="description">If you are going to use a passage of Lorem Ipsum. You need to be sure there isn't anything embarrassing If you are going to use a passage of Lorem Ipsum.</p>
                                                    </div>
                                                    <ul className="list-style--1 mt--30">
                                                        <li><FiCheck /> 5 PPC Campaigns the majority.</li>
                                                        <li><FiCheck /> Digital Marketing going to.</li>
                                                        <li><FiCheck /> Marketing Agency passage of.</li>
                                                        <li><FiCheck /> Seo Friendly you are going.</li>
                                                        <li><FiCheck /> 5 PPC Campaigns the majority.</li>
                                                        <li><FiCheck /> 5 PPC Campaigns the majority.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 mt_md--30 mt_sm--30">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/service-02.png" alt="Service Images" />
                                                </div>
                                            </div>
                                        </div> */}
                {/* End Single Area */}
                {/* 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End Page Wrapper */}


                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default ServiceDetails;