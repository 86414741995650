import React, { Component } from "react";
import { FiCheck } from "react-icons/fi";
import PageHelmet from "../../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/FooterTwo";
import Breadcrumb from "../../elements/common/Breadcrumb";


class ServiceDetails extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false,
            isOpen1: false
        }
        this.openModal = this.openModal.bind(this)
        this.openModal1 = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    openModal1() {
        this.setState({ isOpen1: true })
    }
    render() {
        return (
            <React.Fragment>

                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Survey' />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Survey'} />
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row row--35 sercice-details-content align-items-center">
                                            <div className="col-lg-12 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <div className="section-title">
                                                        <h3 className="title">MI Innovation Community Priority area global survey 2021:</h3>
                                                        <p className="description">A global survey was administered with member countries, active members and other organisations to find innovation priorities in heating and cooling of buildings. </p>
                                                        <img className="w-100 my-3" src="/assets/images/bg/bg-image-18.jpg" alt="Service Images" />
                                                    </div>

                                                    <div className="about-button mt--50 text-center">
                                                        <a className="rn-button-style--2 btn-solid" href="/surveydata" >View</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}


                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default ServiceDetails;