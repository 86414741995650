import React, { Component, Fragment } from "react";
import BlogContent from "./BlogContent";
import { Link } from 'react-router-dom';
import { Card, CardBody } from "reactstrap";

class BLogList extends Component {

    search = (e) => {
        e.preventDefault();
        // emailjs
        //     .sendForm(
        //         'service_p4x3hv8',
        //         'template_jgfr42f',
        //         e.target,
        //         'user_jrfTH2e0Ely35ZCVFdT9S'
        //     )
        //     .then((result) => {
        //         console.log(result.text);
        //     },
        //         (error) => {
        //             console.log(error.text);
        //         }
        //     );
        e.target.reset();
    };

    render() {
        const PostList = BlogContent.slice(0, 6);
        return (
            <Fragment>
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <Card>
                            <CardBody>
                                <div className="form-wrapper">
                                    <form action="" onSubmit={this.search}>
                                        <div className="rn-form-group my-2">
                                            <input
                                                type="text"
                                                name="Country"
                                                placeholder="Country"
                                                required
                                            />
                                        </div>

                                        <div className="rn-form-group my-2">
                                            <input
                                                type="email"
                                                name="email"
                                                placeholder="Keywords"
                                                required
                                            />
                                        </div>

                                        <div className="rn-form-group my-2">
                                            <input
                                                type="text"
                                                name="phone"
                                                placeholder="TRL Levels"
                                                required
                                            />
                                        </div>

                                        <div className="rn-form-group pt-2">
                                            <button className="btn-default" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">Search</button>
                                        </div>
                                    </form>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="row mt_dec--30">
                            {PostList.map((value, i) => (
                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt--30" key={i}>
                                    <div className="im_box">
                                        <div className="thumbnail">
                                            <Link to="/blog-details">
                                                <img className="w-100" src={`/assets/images/blog/blog-${value.images}.jpg`} alt="Blog Images" />
                                            </Link>
                                        </div>
                                        <div className="content">
                                            <div className="inner">
                                                <div className="content_heading">
                                                    <div className="category_list">
                                                        <Link to="/portfolio-details">{value.category}</Link>
                                                    </div>
                                                    <h4 className="title">
                                                        <Link to="/blog-details">{value.title}</Link>
                                                    </h4>
                                                </div>
                                                <div className="content_footer">
                                                    <Link to="/blog-details" className="rn-btn btn-opacity">View</Link>
                                                </div>
                                            </div>
                                            <Link className="transparent_link" to="/blog-details"></Link>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

            </Fragment>
        );
    }
}
export default BLogList;