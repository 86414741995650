import React, { Component } from "react";
import { FiCheck } from "react-icons/fi";
import PageHelmet from "../../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/FooterTwo";
import Breadcrumb from "../../elements/common/Breadcrumb";


class ServiceDetails extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false,
            isOpen1: false
        }
        this.openModal = this.openModal.bind(this)
        this.openModal1 = this.openModal1.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    openModal1() {
        this.setState({ isOpen1: true })
    }
    render() {
        return (
            <React.Fragment>

                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Photos Gallery' />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Photos Gallery'} />
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row row--35 sercice-details-content align-items-center">
                                            <div className="col-lg-12 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <div className="section-title text-center">
                                                        <h2 className="title">Workshop Photos</h2>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-12 my-4">
                                                <h3 className="title">UK Co-Lead involved in the launch of Urban Transitions Mission on 9th November 2021 at COP26:</h3>
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/bg/bg-image-31.jpg" alt="Service Images" />
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-12 my-4">
                                                <h3 className="title">UK Co-Lead involved in the launch of handbook on sustainable cooling of cities on 3rd November 2021 at COP26:</h3>
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/bg/bg-image-32.jpg" alt="Service Images" />
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-12 my-4">
                                                <h3 className="title">Launch of MI 2.0 and Innovation Community on 2nd June 2021, Chile:</h3>
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/bg/bg-image-33.jpg" alt="Service Images" />
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-12 my-4">
                                                <h3 className="title">IC7 deep dive workshop on Thermal Comfort Nov 2019,Newdelhi:</h3>
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/bg/bg-image-29.jpg" alt="Service Images" />
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-12 my-4">
                                                <h3 className="title">IC7 Workshop group photo Nov 2017, Abu Dhabi:</h3>
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/bg/bg-image-28.jpg" alt="Service Images" />
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--3">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row row--35 sercice-details-content align-items-center">
                                            <div className="col-lg-12 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <div className="section-title text-center">
                                                        <h2 className="title">Other Events</h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default ServiceDetails;