import React, { Component } from "react";
import { Link } from 'react-router-dom';
const Portfolio_image = <img src="/assets/images/portfolio/portfolio-4.jpg" alt="React Creative Agency" />;
const Portfolio_image2 = <img src="/assets/images/portfolio/portfolio-5.jpg" alt="React Creative Agency" />;
const Portfolio_image3 = <img src="/assets/images/portfolio/portfolio-6.jpg" alt="React Creative Agency" />;
const Portfolio_image4 = <img src="/assets/images/portfolio/portfolio-7.jpg" alt="React Creative Agency" />;
const Portfolio_image5 = <img src="/assets/images/portfolio/portfolio-8.jpg" alt="React Creative Agency" />;
const Portfolio_image6 = <img src="/assets/images/portfolio/portfolio-9.jpg" alt="React Creative Agency" />;

const PortfolioListContent = [
    {
        image: Portfolio_image,
        category: 'Book',
        title: 'HandBook Launched',
        link: 'https://www.unep.org/resources/report/beating-heat-sustainable-cooling-handbook-cities',
        description: 'A Sustainable Cooling Handbook for Cities'
    },
    {
        image: Portfolio_image2,
        category: 'Collaboration',
        title: 'Comfort Climate Box',
        link: 'https://heatpumpingtechnologies.org/annex55/annex-55-comfort-and-climate-box-explained-in-a-simple-way/',
        description: 'Integrated heating, cooling and energy storage.'
    },
    {
        image: Portfolio_image3,
        category: 'Innovation Competition',
        title: 'Global Cooling Prize',
        link: 'https://globalcoolingprize.org/',
        description: 'Climate-friendly residential cooling solution'
    },
    // {
    //     image: Portfolio_image4,
    //     category: 'Web Design',
    //     title: 'Website Design',
    //     description: 'Lorem ipsum dolor sit amet, consec tetur adipiscing elit.'
    // },
    // {
    //     image: Portfolio_image5,
    //     category: 'Application',
    //     title: 'Web Application',
    //     description: 'Lorem ipsum dolor sit amet, consec tetur adipiscing elit.'
    // },
    // {
    //     image: Portfolio_image6,
    //     category: 'Photoshop',
    //     title: 'Photo Editing',
    //     description: 'Lorem ipsum dolor sit amet, consec tetur adipiscing elit.'
    // }
]

class PortfolioList extends Component {
    render() {
        const { column, styevariation } = this.props;
        const list = PortfolioListContent.slice(0, this.props.item);
        return (
            <React.Fragment>
                {list.map((value, index) => (
                    <div className={`${column}`} key={index}>
                        <div className={`im_portfolio ${styevariation}`}>
                            <div className="thumbnail_inner">
                                <div className="thumbnail">
                                    <a href={value.link} target="_blank">
                                        {value.image}
                                    </a>
                                </div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <div className="portfolio_heading">
                                        <div className="category_list">
                                            <Link to="/portfolio-details">{value.category}</Link>
                                        </div>
                                        <h4 className="title"><a href={value.link} target="_blank">{value.title}</a></h4>
                                    </div>
                                    <div className="portfolio_hover">
                                        <p>{value.description}</p>
                                    </div>
                                </div>
                            </div>
                            <a className="transparent_link" href={value.link} target="_blank"></a>
                        </div>
                    </div>
                ))}

            </React.Fragment>
        )
    }
}
export default PortfolioList;