import React from 'react';
import axios from 'axios'
import { API } from "../../backend";


const Result = () => {
    return (
        <p className="success-message" style={{ fontSize: 25 }}>Your Message has been successfully sent. We will contact you soon.</p>
    )
}

class FormStyle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                name: "",
                email: "",
                phone: "",
                subject: "",
                message: "",
            },
            show: false
        };
    }

    handleChange = (event) => {
        const { formData } = this.state;
        formData[event.target.name] = event.target.value;
        this.setState({ formData });
    };



    handleSubmit = (e) => {
        e.preventDefault();
        let form_data = new FormData();
        form_data.append("fullname", this.state.formData.fullname);
        form_data.append("email", this.state.formData.email);
        form_data.append("phone", this.state.formData.phone);
        form_data.append("subject", this.state.formData.subject);
        form_data.append("message", this.state.formData.message);
        axios
            .post(`${API}/contactus`, form_data, {
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(form_data),
            })
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        formData: {
                            name: "",
                            email: "",
                            phone: "",
                            subject: "",
                            message: "",
                        }, show: true
                    })
                }
            })
    };

    render() {

        return (
            <div className="form-wrapper">
                {!this.state.show ?
                    <form action="" onSubmit={this.handleSubmit}>
                        <div className="rn-form-group">
                            <input
                                type="text"
                                name="fullname"
                                onChange={this.handleChange}
                                placeholder="Your Name"
                                required
                            />
                        </div>

                        <div className="rn-form-group">
                            <input
                                type="email"
                                name="email"
                                onChange={this.handleChange}
                                placeholder="Your Email"
                                required
                            />
                        </div>

                        <div className="rn-form-group">
                            <input
                                type="text"
                                name="phone"
                                onChange={this.handleChange}
                                placeholder="Phone Number"
                                required
                            />
                        </div>

                        <div className="rn-form-group">
                            <input
                                type="text"
                                name="subject"
                                onChange={this.handleChange}
                                placeholder="Subject"
                                required
                            />
                        </div>

                        <div className="rn-form-group">
                            <textarea
                                name="message"
                                onChange={this.handleChange}
                                placeholder="Your Message"
                                required
                            >
                            </textarea>
                        </div>

                        <div className="rn-form-group">
                            <button className="btn-default" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">Submit Now</button>
                        </div>
                    </form>
                    :
                    <div className="rn-form-group">
                        <Result />
                    </div>}
            </div>
        )
    }
}
export default FormStyle;