import React, { Component, Fragment } from "react";
import BlogContent from "../../../elements/blog/BlogContent";
import Popup from "./popup";
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { Card, CardBody } from "reactstrap";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class BLogList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            years: [],
            trl_level: '',
            year: '',
            showmodal: false,
            Id: '',
            technology: '',
            keyword: [],
            country: [],
        }
        this.onchangetrl = this.onchangetrl.bind(this);
        this.onchangecountry = this.onchangecountry.bind(this);
        this.onchangekeyword = this.onchangekeyword.bind(this);

    }
    componentDidMount = () => {
        var arr = []
        for (var i = 1990; i <= new Date().getFullYear(); i++) {
            arr.push(i)
        }
        this.setState({
            years: arr,
        })
    }

    onchangetrl(event) {
        this.setState({
            trl_level: event.target.value
        });
    }

    onchangeyear(event) {
        this.setState({
            year: event.target.value
        });
    }

    onchangecountry(event) {
        var value = []
        for (var i = 0; i < event.length; i++) {
            var arr1 = event[i].value
            value.push(arr1)
        }
        this.setState({
            country: value
        });
    }

    onchangekeyword(event) {
        var value = []
        for (var i = 0; i < event.length; i++) {
            var arr1 = event[i].value
            value.push(arr1)
        }
        this.setState({
            keyword: value
        });
    }

    search = event => {
        event.preventDefault()
        this.props.onSubmit(
            this.state.country,
            this.state.keyword,
            this.state.trl_level,
        )
    }

    toggle = () => {
        this.setState({
            showmodal: !this.state.showmodal
        });
    }

    render() {
        const PostList = BlogContent.slice(0, 6);

        var countryvalue = []
        for (var i = 0; i < this.props.country.length; i++) {
            var arr1 = {
                value: this.props.country[i].id,
                label: this.props.country[i].country_name,
            }
            countryvalue.push(arr1)
        }
        var keywordvalue = []
        for (var i = 0; i < this.props.rta.length; i++) {
            var arr1 = {
                value: this.props.rta[i].id,
                label: this.props.rta[i].keyword,
            }
            keywordvalue.push(arr1)
        }
        const yearvalue = this.state.years.map(item => (
            <option key={item} value={item}>
                {item}
            </option>
        ))
        return (
            <Fragment>
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <Card>
                            <CardBody>
                                <div className="form-wrapper">
                                    <h4 className="text-center mb-0">Filter</h4>
                                    <form action="" onSubmit={this.search}>
                                        {/* <div className="rn-form-group my-4">
                                            <label className="mb-10 pl-1" htmlFor="year" style={{ fontSize: '16px' }}>Select Year:</label>
                                            <select name="year" placeholder="Select Year" id="year" className="form-control" onChange={this.onchangeyear}>
                                                <option key={0} value={0}>Select Year</option>
                                                {yearvalue}
                                            </select>
                                        </div> */}
                                        <div className="rn-form-group my-4">
                                            <label className="mb-10 pl-1" htmlFor="country" style={{ fontSize: '16px' }}>Country:</label>
                                            <Select
                                                isMulti
                                                name="country"
                                                id="country"
                                                options={countryvalue}
                                                placeholder="Country"
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                onChange={this.onchangecountry}
                                            />
                                        </div>

                                        <div className="rn-form-group my-4">
                                            <label className="mb-10 pl-1" htmlFor="country" style={{ fontSize: '16px' }}>Keywords:</label>
                                            <Select
                                                isMulti
                                                name="keyword"
                                                id="keyword"
                                                placeholder="Select Keywords"
                                                options={keywordvalue}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                onChange={this.onchangekeyword}
                                            />

                                        </div>

                                        <div className="rn-form-group my-4">
                                            <label className="mb-10 pl-1" htmlFor="trl_level" style={{ fontSize: '16px' }}>Select TRL Level:</label>
                                            <select name="trl_level" placeholder="Select TRL Level" id="trl_level" className="form-control" onChange={this.onchangetrl}>
                                                <option key={0} value={0}>Select TRL Level</option>
                                                <option key={1} value={1}>{1}</option>
                                                <option key={2} value={2}>{2}</option>
                                                <option key={3} value={3}>{3}</option>
                                                <option key={4} value={4}>{4}</option>
                                                <option key={5} value={5}>{5}</option>
                                                <option key={6} value={6}>{6}</option>
                                                <option key={7} value={7}>{7}</option>
                                                <option key={8} value={8}>{8}</option>
                                                <option key={9} value={9}>{9}</option>
                                            </select>
                                        </div>

                                        <div className="rn-form-group pt-2 text-center">
                                            <button className="btn-default" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">Search</button>
                                        </div>
                                    </form>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="row mt_dec--30">
                            {this.props.randd.map((value, i) => (
                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt--30" key={i}>
                                    <div className="im_box">
                                        <div className="thumbnail">
                                            {value.images.length > 0 ? <Link to="#">
                                                <div
                                                    style={{
                                                        backgroundImage: `url(${value.images[0].image})`,
                                                    }}
                                                    className="tech-img"
                                                ></div>
                                            </Link> : <Link to="#">
                                                <div
                                                    style={{
                                                        backgroundImage: `url(/assets/images/blog/default.png)`,
                                                    }}
                                                    className="tech-img"
                                                ></div>
                                            </Link>}

                                        </div>
                                        <div className="content">
                                            <div className="inner">
                                                <div className="content_heading">
                                                    <div className="category_list">
                                                        <Link to="#">TRL Level: {value.trl_level}</Link>
                                                    </div>
                                                    <h4 className="title">
                                                        <Link to="#" style={{ fontSize: '18px' }}>{value.title}</Link>
                                                    </h4>
                                                </div>
                                                <div className="content_footer">
                                                    <button onClick={() => this.setState({ showmodal: true, Id: value.id, technology: value.title })} className="rn-btn btn-opacity">View</button>
                                                </div>
                                            </div>
                                            <Link className="transparent_link" to="#"></Link>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                        <div className="about-button mt--50">
                            <a className="rn-button-style--2 btn-solid" href="/virtualrepository" >Back to Repository</a>
                        </div>
                    </div>
                </div>
                <Modal isOpen={this.state.showmodal} toggle={this.toggle} className={this.props.className} size="xl">
                    <ModalHeader toggle={this.toggle}>{this.state.technology}</ModalHeader>
                    <ModalBody>
                        <Popup Id={this.state.Id} />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.toggle}>Close</Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }
}
export default BLogList;