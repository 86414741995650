import React from 'react';
import { HorizontalBar, Doughnut } from 'react-chartjs-2';
import { Card, CardBody, CardTitle, Row, Col, Alert } from 'reactstrap';
import axios from 'axios'
import { API } from "../../../backend";
import { Link } from 'react-router-dom';
import PageHelmet from "../../../component/common/Helmet";
import Breadcrumb from "../../../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../../component/header/Header";
import FooterTwo from "../../../component/footer/FooterTwo";
import TechnologyCards from "./cards";
import Pagination from "../../../elements/common/Pagination";
import { intersects } from 'semver';

const colors = ['#052F59', '#384d73', '#5f6d8d', '#0085ea', '#62a7ff', '#8ab8ff', '#abc9ff', '#c9dbff', '#e4edff'];

class VirtualRepository extends React.Component {

    constructor(props) {
        super(props);
        this.dropdownbarOpen = this.dropdownbarOpen.bind(this);
        this.dropdownlineOpen = this.dropdownlineOpen.bind(this);
        this.tabsclick = this.tabsclick.bind(this);
        this.addevent = this.addevent.bind(this);
        this.state = {
            modal: false,
            policycount: 0,
            activitiescount: 0,
            policiescount: 0,
            testbedcount: 0,
            researchfacilitycount: 0,
            randdcount: 0,
            policystackedchart: [],
            policystackedchartlabels: [],
            randdstackedchart: [],
            randdstackedchartlabels: [],
            facilitiesstackedchart: [],
            facilitiesstackedchartlabels: [],
            policypiechart: [],
            relevant_thematic_areas: [],
            researchfacility: [],
            country: [],
            calendarEvents: [
                {
                    title: "Wordcamps",
                    start: new Date("2019-11-17 00:00"),
                    id: "99999998"
                },
                {
                    title: "Apple Event",
                    start: new Date("2019-11-26 00:00"),
                    id: "99999999"
                }
            ],
            dropdownbarOpen: false,
            dropdownlineOpen: false,
            activeTab: '1',
            widths: 150
        }

    }


    componentDidMount = () => {
        // this.fetchpoliciestackedchart()
        // this.fetchpolicypiechart()
        this.fetchcountry()
        this.fetchrelevant_thematic_areas()

    }

    onSearchSubmit = async (country, keyword, year) => {
        var countries = '', keywords = ''
        for (var i = 0; i < country.length; i++) {
            countries = countries + 'countries_involved=' + country[i] + '&'
        }
        for (var i = 0; i < keyword.length; i++) {
            keywords = keywords + 'relevant_thematic_areas=' + keyword[i] + '&'
        }
        if (year)
            axios
                .get(`${API}/research-facility-web?year_developed=${year}&${countries}${keywords}status=true`, {})
                .then(res => {
                    this.setState({
                        researchfacility: res.data.results,
                    })
                })
        else axios
            .get(`${API}/research-facility-web?${countries}${keywords}status=true`, {})
            .then(res => {
                this.setState({
                    researchfacility: res.data.results,
                })
            })
    }

    fetchpoliciestackedchart = () => {
        axios.get(`${API}/policiestackedchart`).then(res => {
            this.setState({
                policiestackedchartlabels: res.data.new_final_list[0].thematic_areas,
            })
            var arr = []
            for (var i = 0; i < res.data.new_final_list.length; i++) {
                var arr1 = {
                    label: res.data.new_final_list[i].country,
                    pointStyle: "triangle",
                    backgroundColor: colors[i],
                    barThickness: 10,
                    categoryPercentage: 1,
                    data: res.data.new_final_list[i].data
                }
                arr.push(arr1)
            }
            console.log(arr, 'arr')
            this.setState({
                policiestackedchart: arr,
            })
        })
    }


    fetchpolicypiechart = () => {
        axios.get(`${API}/policypiechart`).then(res => {
            var arr = []
            var arr1 = {
                labels: res.data.keyword_list_final,

                datasets: [{
                    data: res.data.policycount_list_final,
                    backgroundColor: ['#052F59', '#0085ea', '#62a7ff', '#8ab8ff', '#abc9ff', '#c9dbff', '#e4edff', '#384d73', '#5f6d8d', '#868fa9', '#adb3c5', '#63B5FE', '#82C3FE'],
                    hoverBackgroundColor: ['#052F59', '#0085ea', '#62a7ff', '#8ab8ff', '#abc9ff', '#c9dbff', '#e4edff', '#384d73', '#5f6d8d', '#868fa9', '#adb3c5', '#63B5FE', '#82C3FE']
                }]
            };
            arr.push(arr1)
            this.setState({
                policypiechart: arr,
            })
        })
    }


    fetchrelevant_thematic_areas = (params = {}) => {
        axios.get(`${API}/relevant-thematic-areas`, {}).then(res => {
            this.setState({
                relevant_thematic_areas: res.data.results,
            })
        })
    }

    fetchcountry = (params = {}) => {
        axios.get(`${API}/country`, {}).then(res => {
            this.setState({
                country: res.data.results,
            })
        })
    }

    addevent() {
        this.setState({
            modal: !this.state.modal
        });
    }
    eventClick = eventClick => {
        Alert.fire({
            title: eventClick.event.title,
            html:
                `<div class="table-responsive">
          <table class="table">
          <tbody>
          <tr >
          <td>Title</td>
          <td><strong>` +
                eventClick.event.title +
                `</strong></td>
          </tr>
          <tr >
          <td>Start Time</td>
          <td><strong>
          ` +
                eventClick.event.start +
                `
          </strong></td>
          </tr>
          </tbody>
          </table>
          </div>`,

            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Remove Event",
            cancelButtonText: "Close"
        }).then(result => {
            if (result.value) {
                eventClick.event.remove(); // It will remove event from the calendar
                Alert.fire("Deleted!", "Your Event has been deleted.", "success");
            }
        });
    };
    dropdownbarOpen() {
        this.setState(prevState => ({
            dropdownbarOpen: !prevState.dropdownbarOpen

        }));
    }
    dropdownlineOpen() {
        this.setState(prevState => ({

            dropdownlineOpen: !prevState.dropdownlineOpen
        }));
    }
    tabsclick(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Research Facilities' />
                <Breadcrumb title={'Research Facilities'} />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                {/* <div className="rn-service-details ptb--20 bg_color--3">
                    <div className="container">
                        <div className="row mt-5">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center mb--45 mb_sm--0">
                                    <h2 className="title">R&D Test Beds Gallery</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <Row>
                                    <Col xl={12} className="mb-5">
                                        <Card className="card-statistics h-100">
                                            <CardBody>
                                                <div className="d-block text-center font-weight-bold">
                                                    <CardTitle>Policies</CardTitle>
                                                </div>

                                                <div className="chart-wrapper" style={{ height: 350 }}>
                                                    <HorizontalBar
                                                        pointStyle="star"
                                                        data={{
                                                            labels: this.state.policiestackedchartlabels,
                                                            responsive: true,
                                                            offset: true,
                                                            datasets: this.state.policiestackedchart
                                                        }}
                                                        height={220}
                                                        options={{
                                                            offsetGridLines: true,
                                                            drawTicks: true,
                                                            layout: {
                                                                padding: {
                                                                    top: 30,
                                                                    right: 40,
                                                                    bottom: 40
                                                                }
                                                            },
                                                            legend: {
                                                                display: true,
                                                                position: "right",
                                                                align: "start",
                                                                labels: {
                                                                    usePointStyle: true
                                                                }
                                                            },
                                                            responsive: true,
                                                            maintainAspectRatio: false,
                                                            scales: {
                                                                xAxes: [
                                                                    {
                                                                        stacked: true,
                                                                        gridLines: {
                                                                            drawBorder: false
                                                                        },
                                                                        ticks: {
                                                                            beginAtZero: true,
                                                                            maxTicksLimit: 6,
                                                                            padding: 20,
                                                                            callback(n) {
                                                                                if (n < 1e3) return n;
                                                                                if (n >= 1e3) return +(n / 1e3).toFixed(1) + "K";
                                                                            }
                                                                        }
                                                                    }
                                                                ],
                                                                yAxes: [
                                                                    {
                                                                        stacked: true,
                                                                        ticks: {
                                                                            padding: 5
                                                                        },
                                                                        gridLines: {
                                                                            display: false
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* Start Blog Area */}
                <div className="rn-blog-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center mb--45 mb_sm--0">
                                    <h2 className="title">Search Research Facilities</h2>
                                </div>
                            </div>
                        </div>
                        <TechnologyCards country={this.state.country} rta={this.state.relevant_thematic_areas} researchfacility={this.state.researchfacility} onSubmit={this.onSearchSubmit} />
                        <div className="row mt--60">
                            <div className="col-lg-12">
                                {/* Start Pagination Area */}
                                {/* <Pagination /> */}
                                {/* End Pagination Area */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Area */}
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <FooterTwo />

            </React.Fragment>



        );
    }
}
export default VirtualRepository;