import React, { Component, Fragment } from "react";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import { slideSlick } from "../../page-demo/script";
import ServiceList from "../../elements/service/ServiceList";
import BlogContent from "../../elements/blog/BlogContent";
import BrandTwo from "../../elements/BrandTwo";
import PortfolioList from "../../elements/portfolio/PortfolioList";
import Header from "../../component/header/Header";
import FooterTwo from "../../component/footer/FooterTwo";
import CallAction from "../../elements/callaction/CallAction";
import Team from "../../elements/Team";
import Accordion01 from "../../elements/Accordion";
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Helmet from "../../component/common/Helmet";

const SlideList = [
    {
        textPosition: 'text-left',
        bgImage: 'bg_image--34',
        title: 'Innovation Community',
        description: 'on Affordable Heating and Cooling of Buildings',
        buttonLink: '/contact'
    },
]

const ImageSlideList = [
    {
        textPosition: 'text-left',
        bgImage: 'bg_image--31',
        title: 'UK Co-Lead involved with the launch of the Cities mission on 9th November at COP26.',
    },
    {
        textPosition: 'text-left',
        bgImage: 'bg_image--32',
        title: 'UK Co-Lead involved in the launch of the Cities Cooling Guide on the 3rd November at COP26.',
    },
]

class HomePage extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {

        var namesItemOne = [
            'The Philosophy Of business analytics',
            'Fast-Track Your business',
            'Lies And Damn Lies About business',
            'The Ultimate Deal On business',
        ];
        var namesItemTwo = [
            'Proof That business Really Works',
            'Here Is What You Should Do For Your business',
            'The Hidden Mystery Behind business',
        ];

        const PostList = BlogContent.slice(0, 3);

        return (
            <Fragment>
                <Helmet pageTitle="Home" />

                <Header />
                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    <div className="slider-activation">
                        <Slider className="rn-slick-dot dot-light" {...slideSlick}>
                            {SlideList.map((value, index) => (
                                <div className={`slide slide-style-2 d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index}>
                                    <div className="container pb-0">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className={`inner ${value.textPosition}`}>
                                                    {/* {value.category ? <span>{value.category}</span> : ''} */}
                                                    {/* {value.title ? <h1 style={{ whiteSpace: 'pre-line' }} className="title" >{value.title}</h1> : ''} */}
                                                    {value.title ? <p className="description font-weight-bold pb-3" style={{ fontSize: '62px', color: '#fff' }}>{value.title}</p> : ''}
                                                    {value.description ? <p className="description font-weight-bold" style={{ fontSize: '30px', color: '#fff', paddingBottom: 230 }}><u>{value.description}</u></p> : ''}
                                                    <div className="slide-btn"></div>
                                                    {/* {value.buttonText ? <div className="slide-btn"><a className="btn-default" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''} */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
                {/* End Slider Area   */}



                {/* Start Service Area  */}
                <div className="service-area creative-service-wrapper ptb--120 bg_color--1">
                    <div className="container">
                        {/* <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30 mb_sm--0">
                                    <span className="subtitle">MISSION INNOVATION</span>
                                    <h2 className="title">Innovation Community on AHCB</h2>
                                    
                                </div>
                            </div>
                        </div> */}
                        <div className="row creative-service">
                            <div className="col-lg-12">
                                <ServiceList item="6" column="col-lg-3 col-md-6 col-sm-6 col-12 text-left" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-6 order-1 order-lg-2">
                                <div className="thumbnail position-relative">
                                    <img className="w-100" src="/assets/images/about/about-3.png" alt="About Images" />
                                    <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='OpqILF6w6lU' onClose={() => this.setState({ isOpen: false })} />
                                    <button className="video-popup position-top-center theme-color" onClick={this.openModal}><span className="play-icon"></span></button>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <span className="subtitle">About Us</span>
                                        <h3 className="title" style={{ lineHeight: 1.2 }}>INNOVATION COMMUNITY ON AFFORDABLE HEATING AND COOLING OF BUILDINGS</h3>
                                        <p className="description">The global ‘innovation community’ is focused on low carbon affordable heating and cooling of buildings. We seek to strengthen the clean energy innovation ecosystem and to facilitate new partnerships and collaborations accelerating innovation and providing pathways to net zero.</p>
                                        <p className="description">This global collaboration has included innovation in affordable heating and cooling with clean energy innovation projects in affordable heating and cooling in buildings, including the “Global Cooling Prize”, the “Comfort and Climate Box” and the “Predictive Maintenance and Optimisation project”, involving 50 partners and 15 countries.</p>
                                    </div>
                                    {/* <div className="accordion-wrapper mt--30">
                                        <Accordion01 />
                                    </div> */}
                                    <div className="about-button mt--50">
                                        <a className="rn-button-style--2 btn-solid" href="/about">Know More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}


                {/* Start Brand Area */}
                <div className="rn-brand-area bg_color--1 ptb--60">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}


                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    <div className="slider-activation">
                        <Slider className="rn-slick-dot dot-light" {...slideSlick}>
                            {ImageSlideList.map((value, index) => (
                                <div className={`slide slide-style-2 d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index}>
                                    <div className="container pb-0">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className={`inner ${value.textPosition}`}>
                                                    {value.title ? <p className="description font-weight-bold" style={{ fontSize: '20px', color: '#fff', paddingTop: '150px' }}>{value.title}</p> : ''}
                                                    <div className="slide-btn"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
                {/* End Slider Area   */}

                {/* Start Portfolio Area */}
                <div className="portfolio-area ptb--120 bg_color--1">
                    <div className="portfolio-sacousel-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center mb--30 mb_sm--0">
                                        <span className="subtitle" style={{ fontSize: '40px', lineHeight: '52px' }}>Highlights</span>
                                        {/* <span className="subtitle">MI 2.0 Innovation Community on Affordable Heating and Cooling of Buildings</span> */}
                                        {/* <p className="description">There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p> */}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <PortfolioList styevariation="text-left mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" item="3" />
                            </div>
                            {/* <div className="row">
                                <div className="col-lg-12">
                                    <div className="view-more-btn mt--60 text-center">
                                        <a className="btn-default" href="/portfolio"><span>View More Project</span></a>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}

                {/* Start Team Area  */}
                {/* <div className="rn-team-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                                    <span className="subtitle">Our experts</span>
                                    <h2 className="title">Skilled Team</h2>
                                    <p className="description">There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <Team column="col-lg-4 col-md-6 col-sm-6 col-12" />
                        </div>
                    </div>
                </div> */}
                {/* End Team Area  */}


                {/* Start Blog Area */}
                {/* <div className="rn-blog-area pt--120 pb--80 bg_color--5">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="subtitle">Latest post</span>
                                    <h2 className="title">Latest News</h2>
                                    <p className="description">There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--30">
                            {PostList.map((value, i) => (
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30" key={i}>
                                    <div className="im_box">
                                        <div className="thumbnail">
                                            <Link to="/blog-details">
                                                <img className="w-100" src={`/assets/images/blog/blog-${value.images}.jpg`} alt="Blog Images" />
                                            </Link>
                                        </div>
                                        <div className="content">
                                            <div className="inner">
                                                <div className="content_heading">
                                                    <div className="category_list">
                                                        <Link to="/portfolio-details">{value.category}</Link>
                                                    </div>
                                                    <h4 className="title">
                                                        <Link to="/blog-details">{value.title}</Link>
                                                    </h4>
                                                </div>
                                                <div className="content_footer">
                                                    <Link to="/blog-details" className="rn-btn btn-opacity">Read More</Link>
                                                </div>
                                            </div>
                                            <Link className="transparent_link" to="/blog-details"></Link>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}
                {/* End Blog Area */}

                {/* Start call To Action  */}
                <CallAction />
                {/* End call To Action  */}

                {/* Start Footer Style  */}
                <FooterTwo />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}
export default HomePage;