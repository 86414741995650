import React, { Component } from "react";
import { FiCast, FiLayers, FiUsers, FiMonitor } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiCast />,
        title: 'Mission Innovation 2.0',
        link: 'http://mission-innovation.net/',
        description: 'Global collaboration aimed at accelerating the clean energy revolution.'
    },
    {
        icon: <FiLayers />,
        title: 'Innovation Community Activities',
        link: '/sprint-projects',
        description: 'Updates on events, workshops and meetings.'
    },
    {
        icon: <FiUsers />,
        title: 'Innovation Community Wiki',
        link: '/virtualrepository',
        description: 'Online data platform to capture and share research, innovations, and best practices.'
    },
    {
        icon: <FiMonitor />,
        title: 'Get Involved',
        link: 'https://dashboard.miheatingcoolingwiki.com/',
        description: 'Register yourself and contribute to the innovation community.'
    },
    // {
    //     icon: <FiUsers />,
    //     title: 'Marketing & Reporting',
    //     description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    // },
    // {
    //     icon: <FiMonitor />,
    //     title: 'Mobile App Development',
    //     description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    // }
]


class ServiceThree extends Component {
    render() {
        const { column } = this.props;
        const ServiceContent = ServiceList.slice(0, this.props.item);

        return (
            <React.Fragment>
                <div className="row service-main-wrapper">
                    {ServiceContent.map((val, i) => (
                        <div className={`${column}`} key={i}>
                            <a href={val.link} target="_blank">
                                <div className="service service__style--2 text-left bg-gray">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
