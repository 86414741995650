// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

// Common Layout
// import Layout from "./component/common/App";

import PageScrollTop from './component/PageScrollTop';



// Website Pages
import HomePage from "./pages/homepage/homepage";
import About from "./pages/aboutus/about";
import Contact from "./pages/contact/contact";
import Synergy from "./pages/updatenevents/synergy";
import News from "./pages/updatenevents/news";
import SprintProjects from "./pages/activities/sprintproject";
import RnDPrograms from "./pages/activities/rndprogram";
import Workshops from "./pages/activities/workshops";
import ReferenceMetadata from "./pages/wiki/rmetadata";
import DataPlatform from "./pages/wiki/dataplatform";
import VirtualRepo from "./pages/wiki/virtualrtepo";
import MapData from "./pages/wiki/mapdata";
import MeetTheCommunity from "./pages/homepage/meetthecommunity";
import HVACTech from "./pages/wiki/hvactech/hvactech";
import Policies from "./pages/wiki/policies/policies";
import TestBeds from "./pages/wiki/testbeds/testbeds";
import Activities from "./pages/wiki/activities/activities";
import RandDPrograms from "./pages/wiki/randd/randd";
import ResearchFacilities from "./pages/wiki/researchfacilities/researchfacilities";
import SurveyData from "./pages/wiki/surveydata";
import Survey from "./pages/wiki/survey";
import Podcasts from "./pages/media/podcasts";
import Videos from "./pages/media/videos";
import PhotoGallery from "./pages/media/photos";
import InaugurationPage from "./pages/inauguration/inaugurate";

// Home layout
import Demo from './page-demo/Demo';
import MainDemo from './home/MainDemo';
import Startup from './home/Startup';
import Paralax from './home/Paralax';
import DigitalAgency from './home/DigitalAgency';
import CreativeAgency from './home/CreativeAgency';
import PersonalPortfolio from './home/PersonalPortfolio';
import Business from './home/Business';
import StudioAgency from './home/StudioAgency';
import PortfolioLanding from './home/PortfolioLanding';
import CreativeLanding from './home/CreativeLanding';
import HomeParticles from './home/HomeParticles';
import CreativePortfolio from './home/CreativePortfolio';
import DesignerPortfolio from './home/DesignerPortfolio';
import InteriorLanding from './home/Interior';
import CorporateBusiness from './home/CorporateBusiness';

// Dark Home Layout 
import DarkMainDemo from './dark/MainDemo';
import DarkPortfolioLanding from './dark/PortfolioLanding';
import HomePortfolio from './dark/HomePortfolio';

// Element Layout
import Service from "./elements/Service";
import ServiceDetails from "./elements/ServiceDetails";
import PortfolioDetails from "./elements/PortfolioDetails";
import Blog from "./elements/Blog";
import BlogDetails from "./elements/BlogDetails";
import error404 from "./elements/error404";

// Blocks Layout
import Team from "./blocks/Team";
import Counters from "./blocks/Counters";
import Testimonial from "./blocks/Testimonial";
import Portfolio from "./blocks/Portfolio";
import VideoPopup from "./blocks/VideoPopup";
import Gallery from "./blocks/Gallery";
import Brand from "./blocks/Brand";
import ProgressBar from "./blocks/ProgressBar";
import ContactForm from "./blocks/ContactForm";
import GoogleMap from "./blocks/GoogleMap";
import Columns from "./blocks/Columns";
import PricingTable from "./blocks/PricingTable";
import Button from "./blocks/Button";


import { BrowserRouter, Switch, Route } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

class Root extends Component {
    render() {
        return (
            <BrowserRouter basename={'/'}>
                <PageScrollTop>
                    <Switch>
                        {/* Website Routes */}
                        <Route exact path={`${process.env.PUBLIC_URL}/`} component={HomePage} />
                        <Route exact path={`${process.env.PUBLIC_URL}/home`} component={HomePage} />
                        <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact} />
                        <Route exact path={`${process.env.PUBLIC_URL}/about`} component={About} />
                        <Route exact path={`${process.env.PUBLIC_URL}/synergetic-activities`} component={Synergy} />
                        <Route exact path={`${process.env.PUBLIC_URL}/news`} component={News} />
                        <Route exact path={`${process.env.PUBLIC_URL}/sprint-projects`} component={SprintProjects} />
                        <Route exact path={`${process.env.PUBLIC_URL}/rnd-programs`} component={RnDPrograms} />
                        <Route exact path={`${process.env.PUBLIC_URL}/workshops`} component={Workshops} />
                        <Route exact path={`${process.env.PUBLIC_URL}/reference-metadata`} component={ReferenceMetadata} />
                        <Route exact path={`${process.env.PUBLIC_URL}/dataplatforms`} component={DataPlatform} />
                        <Route exact path={`${process.env.PUBLIC_URL}/virtualrepository`} component={VirtualRepo} />
                        <Route exact path={`${process.env.PUBLIC_URL}/mapdata`} component={MapData} />
                        <Route exact path={`${process.env.PUBLIC_URL}/meetthecommunity`} component={MeetTheCommunity} />
                        <Route exact path={`${process.env.PUBLIC_URL}/hvactechnology`} component={HVACTech} />
                        <Route exact path={`${process.env.PUBLIC_URL}/surveydata`} component={SurveyData} />
                        <Route exact path={`${process.env.PUBLIC_URL}/survey`} component={Survey} />
                        <Route exact path={`${process.env.PUBLIC_URL}/podcasts`} component={Podcasts} />
                        <Route exact path={`${process.env.PUBLIC_URL}/videos`} component={Videos} />
                        <Route exact path={`${process.env.PUBLIC_URL}/photosgallery`} component={PhotoGallery} />
                        <Route exact path={`${process.env.PUBLIC_URL}/inauguration`} component={InaugurationPage} />
                        <Route exact path={`${process.env.PUBLIC_URL}/policies`} component={Policies} />
                        <Route exact path={`${process.env.PUBLIC_URL}/testbeds`} component={TestBeds} />
                        <Route exact path={`${process.env.PUBLIC_URL}/researchfacilities`} component={ResearchFacilities} />
                        <Route exact path={`${process.env.PUBLIC_URL}/activities`} component={Activities} />
                        <Route exact path={`${process.env.PUBLIC_URL}/researchprograms`} component={RandDPrograms} />

                        {/* <Route exact path={`${process.env.PUBLIC_URL}/main-demo`} component={MainDemo} />
                        <Route exact path={`${process.env.PUBLIC_URL}/dark-main-demo`} component={DarkMainDemo} />
                        <Route exact path={`${process.env.PUBLIC_URL}/startup`} component={Startup} />
                        <Route exact path={`${process.env.PUBLIC_URL}/paralax`} component={Paralax} />

                        <Route exact path={`${process.env.PUBLIC_URL}/digital-agency`} component={DigitalAgency} />
                        <Route exact path={`${process.env.PUBLIC_URL}/creative-agency`} component={CreativeAgency} />
                        <Route exact path={`${process.env.PUBLIC_URL}/personal-portfolio`} component={PersonalPortfolio} />
                        <Route exact path={`${process.env.PUBLIC_URL}/studio-agency`} component={StudioAgency} />
                        <Route exact path={`${process.env.PUBLIC_URL}/business`} component={Business} />
                        <Route exact path={`${process.env.PUBLIC_URL}/portfolio-home`} component={HomePortfolio} />
                        <Route exact path={`${process.env.PUBLIC_URL}/portfolio-landing`} component={PortfolioLanding} />
                        <Route exact path={`${process.env.PUBLIC_URL}/creative-landing`} component={CreativeLanding} />
                        <Route exact path={`${process.env.PUBLIC_URL}/home-particles`} component={HomeParticles} />
                        <Route exact path={`${process.env.PUBLIC_URL}/dark-portfolio-landing`} component={DarkPortfolioLanding} />
                        <Route exact path={`${process.env.PUBLIC_URL}/designer-portfolio`} component={DesignerPortfolio} />
                        <Route exact path={`${process.env.PUBLIC_URL}/creative-portfolio`} component={CreativePortfolio} />
                        <Route exact path={`${process.env.PUBLIC_URL}/interior-landing`} component={InteriorLanding} />
                        <Route exact path={`${process.env.PUBLIC_URL}/corporate-business`} component={CorporateBusiness} /> */}


                        {/* Element Layot */}
                        {/* <Route exact path={`${process.env.PUBLIC_URL}/service`} component={Service} />
                        <Route exact path={`${process.env.PUBLIC_URL}/service-details`} component={ServiceDetails} />
                        <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact} />
                        <Route exact path={`${process.env.PUBLIC_URL}/about`} component={About} />
                        <Route exact path={`${process.env.PUBLIC_URL}/portfolio-details`} component={PortfolioDetails} />
                        <Route exact path={`${process.env.PUBLIC_URL}/blog`} component={Blog} />
                        <Route exact path={`${process.env.PUBLIC_URL}/blog-details`} component={BlogDetails} /> */}


                        {/* Blocks Elements  */}
                        {/* <Route exact path={`${process.env.PUBLIC_URL}/button`} component={Button} />
                        <Route exact path={`${process.env.PUBLIC_URL}/team`} component={Team} />
                        <Route exact path={`${process.env.PUBLIC_URL}/counters`} component={Counters} />
                        <Route exact path={`${process.env.PUBLIC_URL}/testimonial`} component={Testimonial} />
                        <Route exact path={`${process.env.PUBLIC_URL}/portfolio`} component={Portfolio} />
                        <Route exact path={`${process.env.PUBLIC_URL}/video-popup`} component={VideoPopup} />
                        <Route exact path={`${process.env.PUBLIC_URL}/gallery`} component={Gallery} />
                        <Route exact path={`${process.env.PUBLIC_URL}/clint-logo`} component={Brand} />
                        <Route exact path={`${process.env.PUBLIC_URL}/progressbar`} component={ProgressBar} />
                        <Route exact path={`${process.env.PUBLIC_URL}/contact-form`} component={ContactForm} />
                        <Route exact path={`${process.env.PUBLIC_URL}/google-map`} component={GoogleMap} />
                        <Route exact path={`${process.env.PUBLIC_URL}/columns`} component={Columns} />
                        <Route exact path={`${process.env.PUBLIC_URL}/pricing-table`} component={PricingTable} /> */}




                        <Route path={`${process.env.PUBLIC_URL}/404`} component={error404} />
                        <Route component={error404} />

                    </Switch>
                </PageScrollTop>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root />, document.getElementById('root'));
serviceWorker.register();