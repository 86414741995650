import React, { Component } from "react";

class BrandTwo extends Component {
    render() {
        return (
            <React.Fragment>
                <h5 className="text-center"><u>CO-LEADS</u></h5>
                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/flag/Co-EU-01.png" alt="Logo Images" />
                    </li>
                    <li>
                        <img src="/assets/images/flag/Co-IN-01-01.png" alt="Logo Images" />
                    </li>
                    <li>
                        <img src="/assets/images/flag/Co-UK-01-01.png" alt="Logo Images" />
                    </li>
                </ul>
                <h5 className="text-center mt-2"><u>PARTICIPANTS</u></h5>
                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/flag/AUS-01.png" alt="Logo Images" />
                    </li>
                    <li>
                        <img src="/assets/images/flag/CAN-01.png" alt="Logo Images" />
                    </li>
                    <li>
                        <img src="/assets/images/flag/FIN-01.png" alt="Logo Images" />
                    </li>
                    <li>
                        <img src="/assets/images/flag/FRA-01.png" alt="Logo Images" />
                    </li>
                    <li>
                        <img src="/assets/images/flag/KOR-01.png" alt="Logo Images" />
                    </li>
                    <li>
                        <img src="/assets/images/flag/MOR-01.png" alt="Logo Images" />
                    </li>
                    <li>
                        <img src="/assets/images/flag/NED-01.png" alt="Logo Images" />
                    </li>
                    <li>
                        <img src="/assets/images/flag/SAU-01.png" alt="Logo Images" />
                    </li>
                    <li>
                        <img src="/assets/images/flag/SWE-01.png" alt="Logo Images" />
                    </li>
                    <li>
                        <img src="/assets/images/flag/UAE-01.png" alt="Logo Images" />
                    </li>
                </ul>
                <h5 className="text-center mt-2"><u>ORGANIZATIONS</u></h5>
                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/org/ES-01.png" alt="Logo Images" />
                    </li>
                    <li>
                        <img src="/assets/images/org/GcoM-01.png" alt="Logo Images" />
                    </li>
                    <li>
                        <img src="/assets/images/org/HPT-01.png" alt="Logo Images" />
                    </li>
                    <li>
                        <img src="/assets/images/org/IEA-01.png" alt="Logo Images" />
                    </li>
                    <li>
                        <img src="/assets/images/org/IIR-01.png" alt="Logo Images" />
                    </li>
                    <li>
                        <img src="/assets/images/org/RMI-01.png" alt="Logo Images" />
                    </li>
                    <li>
                        <img src="/assets/images/org/SCSBR-01.png" alt="Logo Images" />
                    </li>
                    <li>
                        <img src="/assets/images/org/UNEP-01.png" alt="Logo Images" />
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default BrandTwo;