import React from 'react';
import { HorizontalBar, Doughnut } from 'react-chartjs-2';
import { Card, CardBody, CardTitle, Row, Col, Alert, Button } from 'reactstrap';
import axios from 'axios'
import { API } from "../../backend";
import { Link } from 'react-router-dom';
import PageHelmet from "../../component/common/Helmet";
import Breadcrumb from "../../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import FooterTwo from "../../component/footer/FooterTwo";

const data = {
    labels: [
        'Red',
        'Blue',
        'Yellow',
        'Green',
        'Orange'
    ],

    datasets: [{
        data: [400, 50, 100, 80, 150],
        backgroundColor: [
            '#FF6384',
            '#36A2EB',
            '#FFCE56',
            '#00cc99',
            '#ffa31a'
        ],
        hoverBackgroundColor: [
            '#FF6384',
            '#36A2EB',
            '#FFCE56',
            '#00cc99',
            '#ffa31a'
        ]
    }]
};

const stackedData = {
    labels: ["Organic", "Sponsored", "Organic", "Sponsored"],
    previousDate: {
        label: "08/10/2019 - 09/30/2019",
        dataSet: [10000, 150000, 10000, 150000]
    },
    currentDate: {
        label: "10/01/2019 - 11/20/2019",
        dataSet: [10000, 225000, 10000, 225000]
    }
};


var rFactor = function () {
    return Math.round(Math.random() * 100);
};
var barData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [{
        label: 'Apple',
        backgroundColor: '#36a2eb',
        borderColor: '#36a2eb',
        data: [rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor()]
    },
    {
        label: 'Google',
        backgroundColor: '#FF6384',
        borderColor: '#FF6384',
        data: [rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor()]
    }],
};
var lineData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [{
        label: 'Income',
        backgroundColor: 'rgb(56, 182, 202)',
        borderColor: 'rgb(56, 182, 202)',
        pointBorderColor: '#fff',
        data: [rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor()]
    }, {
        label: 'Outcome',
        backgroundColor: 'rgb(249, 249, 249)',
        borderColor: 'rgb(249, 249, 249)',
        pointBorderColor: '#fff',
        data: [rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor()]
    }]
};


const LinechartState = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
        {

            lineTension: 0.1,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: 'rgba(75,192,192,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: [65, 59, 80, 81, 56, 55, 40]
        }
    ]
};

const BarchartState = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
        {
            label: 'January',
            backgroundColor: 'rgba(255,99,132,0.2)',
            borderColor: 'rgba(255,99,132,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(255,99,132,0.4)',
            hoverBorderColor: 'rgba(255,99,132,1)',
            data: [65, 59, 80, 81, 56, 55, 40]
        }
    ]
};

const colors = ['#052F59', '#0085ea', '#62a7ff', '#8ab8ff', '#abc9ff', '#c9dbff', '#e4edff',
    '#384d73', '#5f6d8d', '#868fa9', '#adb3c5', '#63B5FE', '#82C3FE', '#A1D2FE', '#C0E1FE', '#DFF0FE', '#EFF7FE'];

class VirtualRepository extends React.Component {

    constructor(props) {
        super(props);
        this.dropdownbarOpen = this.dropdownbarOpen.bind(this);
        this.dropdownlineOpen = this.dropdownlineOpen.bind(this);
        this.tabsclick = this.tabsclick.bind(this);
        this.addevent = this.addevent.bind(this);
        this.state = {
            modal: false,
            hvaccount: 0,
            activitiescount: 0,
            policiescount: 0,
            testbedcount: 0,
            researchfacilitycount: 0,
            randdcount: 0,
            hvacstackedchart: [],
            hvacstackedchartlabels: [],
            policiestackedchart: [],
            policiestackedchartlabels: [],
            randdstackedchart: [],
            randdstackedchartlabels: [],
            facilitiesstackedchart: [],
            facilitiesstackedchartlabels: [],
            rdprogrampiechart: [],
            hvacpiechart: [],
            calendarEvents: [
                {
                    title: "Wordcamps",
                    start: new Date("2019-11-17 00:00"),
                    id: "99999998"
                },
                {
                    title: "Apple Event",
                    start: new Date("2019-11-26 00:00"),
                    id: "99999999"
                }
            ],
            dropdownbarOpen: false,
            dropdownlineOpen: false,
            activeTab: '1',
            widths: 150
        }

    }


    componentDidMount = () => {
        this.fetchhvaccount()
        this.fetchactivitiescount()
        this.fetchpoliciescount()
        this.fetchtestbedcount()
        this.fetchresearchfacilitycount()
        this.fetchranddcount()
        this.fetchhvacstackedchart()
        this.fetchpoliciestackedchart()
        this.fetchranddstackedchart()
        this.fetchrdprogrampiechart()
        this.fetchhvacpiechart()
        // this.fetchfacilitiesstackedchart()
        // this.fetchrdprogrampiechart()
        // this.fetchresearchfacilitychart()
    }

    fetchhvaccount = () => {
        axios.get(`${API}/hvaccount`).then(res => {
            this.setState({
                hvaccount: res.data.count,
            })
        })
    }

    fetchactivitiescount = () => {
        axios.get(`${API}/activitiescount`).then(res => {
            this.setState({
                activitiescount: res.data.count,
            })
        })
    }

    fetchpoliciescount = () => {
        axios.get(`${API}/policiescount`).then(res => {
            this.setState({
                policiescount: res.data.count,
            })
        })
    }

    fetchtestbedcount = () => {
        axios.get(`${API}/testbedcount`).then(res => {
            this.setState({
                testbedcount: res.data.count,
            })
        })
    }

    fetchresearchfacilitycount = () => {
        axios.get(`${API}/researchfacilitycount`).then(res => {
            this.setState({
                researchfacilitycount: res.data.count,
            })
        })
    }

    fetchranddcount = () => {
        axios.get(`${API}/randdcount`).then(res => {
            this.setState({
                randdcount: res.data.count,
            })
        })
    }

    fetchhvacstackedchart = () => {
        axios.get(`${API}/hvacstackedchart`).then(res => {
            this.setState({
                hvacstackedchartlabels: res.data.new_final_list[0].thematic_areas,
            })
            var arr = []
            for (var i = 0; i < res.data.new_final_list.length; i++) {
                var arr1 = {
                    label: res.data.new_final_list[i].country,
                    pointStyle: "triangle",
                    backgroundColor: colors[i],
                    barThickness: 10,
                    categoryPercentage: 1,
                    data: res.data.new_final_list[i].data
                }
                arr.push(arr1)
            }
            console.log(arr, 'arr')
            this.setState({
                hvacstackedchart: arr,
            })
        })
    }

    fetchpoliciestackedchart = () => {
        axios.get(`${API}/policiestackedchart`).then(res => {
            this.setState({
                policiestackedchartlabels: res.data.new_final_list[0].thematic_areas,
            })
            var arr = []
            for (var i = 0; i < res.data.new_final_list.length; i++) {
                var arr1 = {
                    label: res.data.new_final_list[i].country,
                    pointStyle: "triangle",
                    backgroundColor: colors[i],
                    barThickness: 10,
                    categoryPercentage: 1,
                    data: res.data.new_final_list[i].data
                }
                arr.push(arr1)
            }
            console.log(arr, 'arr')
            this.setState({
                policiestackedchart: arr,
            })
        })
    }

    fetchranddstackedchart = () => {
        axios.get(`${API}/randdstackedchart`).then(res => {
            this.setState({
                randdstackedchartlabels: res.data.new_final_list[0].thematic_areas,
            })
            var arr = []
            for (var i = 0; i < res.data.new_final_list.length; i++) {
                var arr1 = {
                    label: res.data.new_final_list[i].country,
                    pointStyle: "triangle",
                    backgroundColor: colors[i],
                    barThickness: 10,
                    categoryPercentage: 1,
                    data: res.data.new_final_list[i].data
                }
                arr.push(arr1)
            }
            console.log(arr, 'arr')
            this.setState({
                randdstackedchart: arr,
            })
        })
    }

    fetchfacilitiesstackedchart = () => {
        axios.get(`${API}/facilitiesstackedchart`).then(res => {
            this.setState({
                facilitiesstackedchartlabels: res.data.new_final_list[0].thematic_areas,
            })
            var arr = []
            for (var i = 0; i < res.data.new_final_list.length; i++) {
                var arr1 = {
                    label: res.data.new_final_list[i].country,
                    pointStyle: "triangle",
                    backgroundColor: colors[i],
                    barThickness: 10,
                    categoryPercentage: 1,
                    data: res.data.new_final_list[i].data
                }
                arr.push(arr1)
            }
            console.log(arr, 'arr')
            this.setState({
                facilitiesstackedchart: arr,
            })
        })
    }


    fetchrdprogrampiechart = () => {
        axios.get(`${API}/rdprogrampiechart`).then(res => {
            var arr = []
            var arr1 = {
                labels: res.data.keyword_list_final,

                datasets: [{
                    data: res.data.funding_list_final,
                    backgroundColor: ['#052F59', '#0085ea', '#62a7ff', '#8ab8ff', '#abc9ff', '#c9dbff', '#e4edff', '#384d73', '#5f6d8d', '#868fa9', '#adb3c5', '#63B5FE', '#82C3FE'],
                    hoverBackgroundColor: ['#052F59', '#0085ea', '#62a7ff', '#8ab8ff', '#abc9ff', '#c9dbff', '#e4edff', '#384d73', '#5f6d8d', '#868fa9', '#adb3c5', '#63B5FE', '#82C3FE']
                }]
            };
            arr.push(arr1)
            this.setState({
                rdprogrampiechart: arr,
            })
        })
    }



    fetchhvacpiechart = () => {
        axios.get(`${API}/hvacpiechart`).then(res => {
            var arr = []
            var arr1 = {
                labels: res.data.keyword_list_final,

                datasets: [{
                    data: res.data.hvaccount_list_final,
                    backgroundColor: ['#052F59', '#0085ea', '#62a7ff', '#8ab8ff', '#abc9ff', '#c9dbff', '#e4edff', '#384d73', '#5f6d8d', '#868fa9', '#adb3c5', '#63B5FE', '#82C3FE'],
                    hoverBackgroundColor: ['#052F59', '#0085ea', '#62a7ff', '#8ab8ff', '#abc9ff', '#c9dbff', '#e4edff', '#384d73', '#5f6d8d', '#868fa9', '#adb3c5', '#63B5FE', '#82C3FE']
                }]
            };
            arr.push(arr1)
            this.setState({
                hvacpiechart: arr,
            })
        })
    }



    fetchresearchfacilitychart = () => {
        axios.get(`${API}/researchfacilitychart`).then(res => {
            this.setState({
                researchfacilitychartlabels: res.data.new_final_list[0].thematic_areas,
            })
            var arr = []
            for (var i = 0; i < res.data.new_final_list.length; i++) {
                var arr1 = {
                    label: res.data.new_final_list[i].country,
                    pointStyle: "triangle",
                    backgroundColor: colors[i],
                    barThickness: 10,
                    categoryPercentage: 1,
                    data: res.data.new_final_list[i].data
                }
                arr.push(arr1)
            }
            console.log(arr, 'arr')
            this.setState({
                researchfacilitychart: arr,
            })
        })
    }



    addevent() {
        this.setState({
            modal: !this.state.modal
        });
    }
    eventClick = eventClick => {
        Alert.fire({
            title: eventClick.event.title,
            html:
                `<div class="table-responsive">
          <table class="table">
          <tbody>
          <tr >
          <td>Title</td>
          <td><strong>` +
                eventClick.event.title +
                `</strong></td>
          </tr>
          <tr >
          <td>Start Time</td>
          <td><strong>
          ` +
                eventClick.event.start +
                `
          </strong></td>
          </tr>
          </tbody>
          </table>
          </div>`,

            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Remove Event",
            cancelButtonText: "Close"
        }).then(result => {
            if (result.value) {
                eventClick.event.remove(); // It will remove event from the calendar
                Alert.fire("Deleted!", "Your Event has been deleted.", "success");
            }
        });
    };
    dropdownbarOpen() {
        this.setState(prevState => ({
            dropdownbarOpen: !prevState.dropdownbarOpen

        }));
    }
    dropdownlineOpen() {
        this.setState(prevState => ({

            dropdownlineOpen: !prevState.dropdownlineOpen
        }));
    }
    tabsclick(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    componentWillMount() {
        this.setState(LinechartState);
        this.setState(BarchartState);
    }

    render() {

        const colors = ['#052F59', '#0085ea', '##60B7FE', '#8ab8ff', '#abc9ff', '#c9dbff', '#e4edff',
            '#384d73', '#5f6d8d', '#868fa9', '#adb3c5', '#63B5FE', '#82C3FE', '#A1D2FE', '#C0E1FE', '#DFF0FE', '#EFF7FE'];
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Virtual Repository' />
                <Breadcrumb title={'Virtual Repository'} />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                <div className="rn-service-details ptb--20 bg_color--3">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                {/* <!-- widgets --> */}
                                <Row>
                                    <Col xl={4} lg={6} md={6} className="mb-5" >
                                        <Link to="/hvactechnology">
                                            <Card className="card-statistics h-80">
                                                <CardBody style={{ borderBottom: '6px solid #00008b', borderRadius: '4px' }}>
                                                    <div className="clearfix">
                                                        <div className="text-center">
                                                            <p className="card-text text-dark">HVAC Technologies</p>
                                                            <h4>{this.state.hvaccount}</h4>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Link>
                                    </Col>
                                    <Col xl={4} lg={6} md={6} className="mb-5" >
                                        <Link to="/activities">
                                            <Card className="card-statistics h-80">
                                                <CardBody style={{ borderBottom: '6px solid #0085ea', borderRadius: '4px' }}>
                                                    <div className="clearfix">
                                                        <div className="text-center">
                                                            <p className="card-text text-dark">Activities</p>
                                                            <h4 >{this.state.activitiescount}</h4>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Link>
                                    </Col>
                                    <Col xl={4} lg={6} md={6} className="mb-5" >
                                        <Link to="/policies">
                                            <Card className="card-statistics h-80">
                                                <CardBody style={{ borderBottom: '6px solid #82C3FE', borderRadius: '4px' }}>
                                                    <div className="clearfix">
                                                        <div className="text-center">
                                                            <p className="card-text text-dark">Policies</p>
                                                            <h4>{this.state.policiescount}</h4>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Link>
                                    </Col>
                                    <Col xl={4} lg={6} md={6} className="mb-5" >
                                        <Link to="/testbeds">
                                            <Card className="card-statistics h-80">
                                                <CardBody style={{ borderBottom: '6px solid #775BC9', borderRadius: '4px' }}>
                                                    <div className="clearfix">
                                                        <div className="text-center">
                                                            <p className="card-text text-dark">R&D Test Beds</p>
                                                            <h4>{this.state.testbedcount}</h4>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Link>
                                    </Col>
                                    <Col xl={4} lg={6} md={6} className="mb-5" >
                                        <Link to="/researchfacilities">
                                            <Card className="card-statistics h-80">
                                                <CardBody style={{ borderBottom: '6px solid #9A7CFB', borderRadius: '4px' }}>
                                                    <div className="clearfix">
                                                        <div className="text-center">
                                                            <p className="card-text text-dark">Research Facilities</p>
                                                            <h4>{this.state.researchfacilitycount}</h4>
                                                        </div>
                                                    </div>
                                                </CardBody>

                                            </Card>
                                        </Link>
                                    </Col>
                                    <Col xl={4} lg={6} md={6} className="mb-5" >
                                        <Link to="/researchprograms">
                                            <Card className="card-statistics h-80">
                                                <CardBody style={{ borderBottom: '6px solid #80ABF4', borderRadius: '4px' }}>
                                                    <div className="clearfix">
                                                        <div className="text-center">
                                                            <p className="card-text text-dark">R&D Programs</p>
                                                            <h4>{this.state.randdcount}</h4>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Link>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={12} className="mb-5 text-center">
                                        <Link to="/mapdata">
                                            <button className="btn-default" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">View Data on Map</button>
                                        </Link>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={12} className="mb-5">
                                        <Card className="card-statistics h-100">
                                            <CardBody>
                                                <div className="d-block">
                                                    <CardTitle>HVAC Technologies</CardTitle>
                                                </div>

                                                <div className="chart-wrapper" style={{ height: 350 }}>
                                                    <HorizontalBar
                                                        pointStyle="star"
                                                        data={{
                                                            labels: this.state.hvacstackedchartlabels,
                                                            responsive: true,
                                                            offset: true,
                                                            datasets: this.state.hvacstackedchart
                                                        }}
                                                        height={220}
                                                        options={{
                                                            offsetGridLines: true,
                                                            drawTicks: true,
                                                            layout: {
                                                                padding: {
                                                                    top: 30,
                                                                    right: 40,
                                                                    bottom: 40
                                                                }
                                                            },
                                                            legend: {
                                                                display: true,
                                                                position: "right",
                                                                align: "start",
                                                                labels: {
                                                                    usePointStyle: true
                                                                }
                                                            },
                                                            responsive: true,
                                                            maintainAspectRatio: false,
                                                            scales: {
                                                                xAxes: [
                                                                    {
                                                                        stacked: true,
                                                                        gridLines: {
                                                                            drawBorder: false
                                                                        },
                                                                        ticks: {
                                                                            beginAtZero: true,
                                                                            maxTicksLimit: 6,
                                                                            padding: 20,
                                                                            callback(n) {
                                                                                if (n < 1e3) return n;
                                                                                if (n >= 1e3) return +(n / 1e3).toFixed(1) + "K";
                                                                            }
                                                                        }
                                                                    }
                                                                ],
                                                                yAxes: [
                                                                    {
                                                                        stacked: true,
                                                                        ticks: {
                                                                            padding: 5
                                                                        },
                                                                        gridLines: {
                                                                            display: false
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>

                                    <Col xl={12} className="mb-5">
                                        <Card className="card-statistics h-100">
                                            <CardBody>
                                                <div className="d-block">
                                                    <CardTitle>R&D Programs</CardTitle>
                                                </div>

                                                <div className="chart-wrapper" style={{ height: 350 }}>
                                                    <HorizontalBar
                                                        pointStyle="star"
                                                        data={{
                                                            labels: this.state.randdstackedchartlabels,
                                                            responsive: true,
                                                            offset: true,
                                                            datasets: this.state.randdstackedchart
                                                        }}
                                                        height={220}
                                                        options={{
                                                            offsetGridLines: true,
                                                            drawTicks: true,
                                                            layout: {
                                                                padding: {
                                                                    top: 30,
                                                                    right: 40,
                                                                    bottom: 40
                                                                }
                                                            },
                                                            legend: {
                                                                display: true,
                                                                position: "right",
                                                                align: "start",
                                                                labels: {
                                                                    usePointStyle: true
                                                                }
                                                            },
                                                            responsive: true,
                                                            maintainAspectRatio: false,
                                                            scales: {
                                                                xAxes: [
                                                                    {
                                                                        stacked: true,
                                                                        gridLines: {
                                                                            drawBorder: false
                                                                        },
                                                                        ticks: {
                                                                            beginAtZero: true,
                                                                            maxTicksLimit: 6,
                                                                            padding: 20,
                                                                            callback(n) {
                                                                                if (n < 1e3) return n;
                                                                                if (n >= 1e3) return +(n / 1e3).toFixed(1) + "K";
                                                                            }
                                                                        }
                                                                    }
                                                                ],
                                                                yAxes: [
                                                                    {
                                                                        stacked: true,
                                                                        ticks: {
                                                                            padding: 5
                                                                        },
                                                                        gridLines: {
                                                                            display: false
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>

                                    <Col xl={12} className="mb-5">
                                        <Card className="card-statistics h-100">
                                            <CardBody>
                                                <div className="d-block">
                                                    <CardTitle>Policies</CardTitle>
                                                </div>

                                                <div className="chart-wrapper" style={{ height: 350 }}>
                                                    <HorizontalBar
                                                        pointStyle="star"
                                                        data={{
                                                            labels: this.state.policiestackedchartlabels,
                                                            responsive: true,
                                                            offset: true,
                                                            datasets: this.state.policiestackedchart
                                                        }}
                                                        height={220}
                                                        options={{
                                                            offsetGridLines: true,
                                                            drawTicks: true,
                                                            layout: {
                                                                padding: {
                                                                    top: 30,
                                                                    right: 40,
                                                                    bottom: 40
                                                                }
                                                            },
                                                            legend: {
                                                                display: true,
                                                                position: "right",
                                                                align: "start",
                                                                labels: {
                                                                    usePointStyle: true
                                                                }
                                                            },
                                                            responsive: true,
                                                            maintainAspectRatio: false,
                                                            scales: {
                                                                xAxes: [
                                                                    {
                                                                        stacked: true,
                                                                        gridLines: {
                                                                            drawBorder: false
                                                                        },
                                                                        ticks: {
                                                                            beginAtZero: true,
                                                                            maxTicksLimit: 6,
                                                                            padding: 20,
                                                                            callback(n) {
                                                                                if (n < 1e3) return n;
                                                                                if (n >= 1e3) return +(n / 1e3).toFixed(1) + "K";
                                                                            }
                                                                        }
                                                                    }
                                                                ],
                                                                yAxes: [
                                                                    {
                                                                        stacked: true,
                                                                        ticks: {
                                                                            padding: 5
                                                                        },
                                                                        gridLines: {
                                                                            display: false
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>

                                    <Col xl={6} className="mb-5">
                                        <Card className="h-100">
                                            <CardBody>
                                                <CardTitle>R&D Funding (in Million USD)</CardTitle>
                                                <div className="chart-wrapper" style={{ height: 270 }}>
                                                    <Doughnut data={this.state.rdprogrampiechart[0]} options={{ maintainAspectRatio: false, legend: { display: true, labels: { fontFamily: "Poppins" } } }} width={this.state.widths} />
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col xl={6} className="mb-5">
                                        <Card className="h-100">
                                            <CardBody>
                                                <CardTitle>HVAC Technologies (in Numbers)</CardTitle>
                                                <div className="chart-wrapper" style={{ height: 270 }}>
                                                    <Doughnut data={this.state.hvacpiechart[0]} options={{ maintainAspectRatio: false, legend: { display: true, labels: { fontFamily: "Poppins" } } }} width={this.state.widths} />
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <FooterTwo />

            </React.Fragment>



        );
    }
}
export default VirtualRepository;