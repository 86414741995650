import React from 'react';
import { HorizontalBar, Doughnut } from 'react-chartjs-2';
import { Card, CardBody, CardTitle, Row, Col, Alert } from 'reactstrap';
import axios from 'axios'
import { API } from "../../../backend";
import { Link } from 'react-router-dom';
import PageHelmet from "../../../component/common/Helmet";
import Breadcrumb from "../../../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../../component/header/Header";
import FooterTwo from "../../../component/footer/FooterTwo";
import TechnologyCards from "./cards";

const colors = ['#052F59', '#384d73', '#5f6d8d', '#0085ea', '#62a7ff', '#8ab8ff', '#abc9ff', '#c9dbff', '#e4edff'];

class VirtualRepository extends React.Component {

    constructor(props) {
        super(props);
        this.dropdownbarOpen = this.dropdownbarOpen.bind(this);
        this.dropdownlineOpen = this.dropdownlineOpen.bind(this);
        this.tabsclick = this.tabsclick.bind(this);
        this.addevent = this.addevent.bind(this);
        this.state = {
            modal: false,
            hvaccount: 0,
            activitiescount: 0,
            policiescount: 0,
            testbedcount: 0,
            researchfacilitycount: 0,
            randdcount: 0,
            rdpatentsbarchart: [],
            hvacstackedchartlabels: [],
            policiestackedchart: [],
            policiestackedchartlabels: [],
            randdstackedchart: [],
            randdstackedchartlabels: [],
            facilitiesstackedchart: [],
            facilitiesstackedchartlabels: [],
            hvacpiechart: [],
            relevant_thematic_areas: [],
            rdprogrampiechart: [],
            randd: [],
            country: [],
            calendarEvents: [
                {
                    title: "Wordcamps",
                    start: new Date("2019-11-17 00:00"),
                    id: "99999998"
                },
                {
                    title: "Apple Event",
                    start: new Date("2019-11-26 00:00"),
                    id: "99999999"
                }
            ],
            dropdownbarOpen: false,
            dropdownlineOpen: false,
            activeTab: '1',
            widths: 150
        }

    }


    componentDidMount = () => {
        this.fetchpatents()
        this.fetchrdprogrampiechart()
        this.fetchcountry()
        this.fetchrelevant_thematic_areas()

    }

    onSearchSubmit = async (country, keyword, trl) => {
        var countries = '', keywords = ''
        for (var i = 0; i < country.length; i++) {
            countries = countries + 'countries_involved=' + country[i] + '&'
        }
        for (var i = 0; i < keyword.length; i++) {
            keywords = keywords + 'relevant_thematic_areas=' + keyword[i] + '&'
        }
        axios
            .get(`${API}/research-and-development-web?trl_level=${trl}&${countries}${keywords}status=true`, {})
            .then(res => {
                this.setState({
                    randd: res.data.results,
                })
            })
    }

    fetchpatents = () => {
        axios.get(`${API}/rdpatentsbarchart`).then(res => {
            var arr = []
            var arr1 = {
                labels: res.data.keyword_list_final,
                datasets: [{
                    label: "Patents (in Numbers)",
                    backgroundColor: '#61B8FF',
                    borderColor: '#61B8FF',
                    data: res.data.patents_list_final
                }],
            };
            arr.push(arr1)
            this.setState({
                rdpatentsbarchart: arr,
            })
        })
    }


    fetchrdprogrampiechart = () => {
        axios.get(`${API}/rdprogrampiechart`).then(res => {
            var arr = []
            var arr1 = {
                labels: res.data.keyword_list_final,

                datasets: [{
                    data: res.data.funding_list_final,
                    backgroundColor: ['#052F59', '#0085ea', '#62a7ff', '#8ab8ff', '#abc9ff', '#c9dbff', '#e4edff', '#384d73', '#5f6d8d', '#868fa9', '#adb3c5', '#63B5FE', '#82C3FE'],
                    hoverBackgroundColor: ['#052F59', '#0085ea', '#62a7ff', '#8ab8ff', '#abc9ff', '#c9dbff', '#e4edff', '#384d73', '#5f6d8d', '#868fa9', '#adb3c5', '#63B5FE', '#82C3FE']
                }]
            };
            arr.push(arr1)
            this.setState({
                rdprogrampiechart: arr,
            })
        })
    }


    fetchrelevant_thematic_areas = (params = {}) => {
        axios.get(`${API}/relevant-thematic-areas`, {}).then(res => {
            this.setState({
                relevant_thematic_areas: res.data.results,
            })
        })
    }

    fetchcountry = (params = {}) => {
        axios.get(`${API}/country`, {}).then(res => {
            this.setState({
                country: res.data.results,
            })
        })
    }

    addevent() {
        this.setState({
            modal: !this.state.modal
        });
    }
    eventClick = eventClick => {
        Alert.fire({
            title: eventClick.event.title,
            html:
                `<div class="table-responsive">
          <table class="table">
          <tbody>
          <tr >
          <td>Title</td>
          <td><strong>` +
                eventClick.event.title +
                `</strong></td>
          </tr>
          <tr >
          <td>Start Time</td>
          <td><strong>
          ` +
                eventClick.event.start +
                `
          </strong></td>
          </tr>
          </tbody>
          </table>
          </div>`,

            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Remove Event",
            cancelButtonText: "Close"
        }).then(result => {
            if (result.value) {
                eventClick.event.remove(); // It will remove event from the calendar
                Alert.fire("Deleted!", "Your Event has been deleted.", "success");
            }
        });
    };
    dropdownbarOpen() {
        this.setState(prevState => ({
            dropdownbarOpen: !prevState.dropdownbarOpen

        }));
    }
    dropdownlineOpen() {
        this.setState(prevState => ({

            dropdownlineOpen: !prevState.dropdownlineOpen
        }));
    }
    tabsclick(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='R&D Programs' />
                <Breadcrumb title={'R&D Programs'} />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                <div className="rn-service-details ptb--20 bg_color--3">
                    <div className="container">
                        <div className="row mt-5">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center mb--45 mb_sm--0">
                                    <h2 className="title">R&D Programs Gallery</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                {/* <!-- widgets --> */}
                                <Row>
                                    <Col xl={8} className="mb-5">
                                        <Card className="card-statistics h-100">
                                            <CardBody>
                                                <div className="d-block text-center font-weight-bold">
                                                    <CardTitle>R&D Patents</CardTitle>
                                                </div>

                                                <div className="chart-wrapper" style={{ height: 350 }}>
                                                    <HorizontalBar data={this.state.rdpatentsbarchart[0]} width={this.state.widths} options={{ maintainAspectRatio: false, legend: { display: true, labels: { fontFamily: "Poppins" } }, scales: { yAxes: [{ gridLines: { display: false }, ticks: { fontFamily: "Poppins" } }], xAxes: [{ gridLines: { display: false }, ticks: { fontFamily: "Poppins", beginAtZero: true } }] } }} className="scrollbar-x text-center" />
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>

                                    <Col xl={4} className="mb-5">
                                        <Card className="h-100">
                                            <CardBody>
                                                <CardTitle>R&D Funding (in Million USD)</CardTitle>
                                                <div className="chart-wrapper" style={{ height: 270 }}>
                                                    <Doughnut data={this.state.rdprogrampiechart[0]} options={{ maintainAspectRatio: false, legend: { display: true, labels: { fontFamily: "Poppins" } } }} width={this.state.widths} />
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Start Blog Area */}
                <div className="rn-blog-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center mb--45 mb_sm--0">
                                    <h2 className="title">Search R&D Programs</h2>
                                </div>
                            </div>
                        </div>
                        <TechnologyCards country={this.state.country} rta={this.state.relevant_thematic_areas} randd={this.state.randd} onSubmit={this.onSearchSubmit} />
                        <div className="row mt--60">
                            <div className="col-lg-12">
                                {/* Start Pagination Area */}
                                {/* <Pagination /> */}
                                {/* End Pagination Area */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Area */}
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <FooterTwo />

            </React.Fragment>



        );
    }
}
export default VirtualRepository;