import React, { Component, Fragment } from "react";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import { slideSlick } from "../../page-demo/script";
import ServiceList from "../../elements/service/ServiceList";
import BlogContent from "../../elements/blog/BlogContent";
import BrandTwo from "../../elements/BrandTwo";
import PortfolioList from "../../elements/portfolio/PortfolioList";
import Breadcrumb from "../../elements/common/Breadcrumb";
import Header from "../../component/header/Header";
import FooterTwo from "../../component/footer/FooterTwo";
import CallAction from "../../elements/callaction/CallAction";
import Team from "../../elements/Team";
import Accordion01 from "../../elements/Accordion";
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Helmet from "../../component/common/Helmet";

const SlideList = [
    {
        textPosition: 'text-left',
        bgImage: 'bg_image--34',
        title: 'Innovation Community',
        description: 'on Affordable Heating and Cooling of Buildings',
        buttonLink: '/contact'
    },
]

const ImageSlideList = [
    {
        textPosition: 'text-left',
        bgImage: 'bg_image--31',
        title: 'UK Co-Lead involved with the launch of the Cities mission on 9th November at COP26.',
    },
    {
        textPosition: 'text-left',
        bgImage: 'bg_image--32',
        title: 'UK Co-Lead involved in the launch of the Cities Cooling Guide on the 3rd November at COP26.',
    },
]

class HomePage extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {

        var namesItemOne = [
            'The Philosophy Of business analytics',
            'Fast-Track Your business',
            'Lies And Damn Lies About business',
            'The Ultimate Deal On business',
        ];
        var namesItemTwo = [
            'Proof That business Really Works',
            'Here Is What You Should Do For Your business',
            'The Hidden Mystery Behind business',
        ];

        const PostList = BlogContent.slice(0, 3);

        return (
            <Fragment>
                <Helmet pageTitle="Meet the Community" />

                <Header />
                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Meet the Community'} />
                {/* End Breadcrump Area */}
                {/* Start Team Area  */}
                <div className="rn-team-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                                    <span className="subtitle">Community</span>
                                    <h2 className="title">Meet our Community</h2>
                                    {/* <p className="description">There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p> */}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <Team column="col-lg-2 col-md-4 col-sm-6 col-12" />
                        </div>
                    </div>
                </div>
                {/* End Team Area  */}


                {/* Start Blog Area */}
                {/* <div className="rn-blog-area pt--120 pb--80 bg_color--5">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="subtitle">Latest post</span>
                                    <h2 className="title">Latest News</h2>
                                    <p className="description">There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--30">
                            {PostList.map((value, i) => (
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30" key={i}>
                                    <div className="im_box">
                                        <div className="thumbnail">
                                            <Link to="/blog-details">
                                                <img className="w-100" src={`/assets/images/blog/blog-${value.images}.jpg`} alt="Blog Images" />
                                            </Link>
                                        </div>
                                        <div className="content">
                                            <div className="inner">
                                                <div className="content_heading">
                                                    <div className="category_list">
                                                        <Link to="/portfolio-details">{value.category}</Link>
                                                    </div>
                                                    <h4 className="title">
                                                        <Link to="/blog-details">{value.title}</Link>
                                                    </h4>
                                                </div>
                                                <div className="content_footer">
                                                    <Link to="/blog-details" className="rn-btn btn-opacity">Read More</Link>
                                                </div>
                                            </div>
                                            <Link className="transparent_link" to="/blog-details"></Link>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}
                {/* End Blog Area */}

                {/* Start call To Action  */}
                <CallAction />
                {/* End call To Action  */}

                {/* Start Footer Style  */}
                <FooterTwo />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}
export default HomePage;