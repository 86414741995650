import React, { Component } from "react";
import { FiSend } from "react-icons/fi";

import { Link } from 'react-router-dom';
class About extends Component {
    render() {
        let title = 'Objectives',
            bold1 = 'Convene',
            bold2 = 'Facilitate research collaborations and accelerate private investment',
            bold3 = 'Provide a repository',
            bold4 = 'Communicate beyond the community',
            list1 = ' the global research and innovation community for heating and cooling of buildings, with a shared and prioritised innovation agenda and dedicated working groups;',
            list2 = ' in delivering significant innovation and impact across the international heating and cooling space;',
            list3 = ' with information of state-of-the-art innovation topics; and,',
            list4 = ' beyond the community, disseminating the challenges and opportunities of low carbon heating and cooling to wider audiences.';

        return (
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            {/* <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about-1.jpg" alt="About Images" />
                                </div>
                            </div> */}
                            <div className="col-lg-12 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <div className="icon">
                                            <FiSend />
                                        </div>
                                        <h2 className="title">{title}</h2>
                                        <ul>
                                            <li className="pb-2"><p className="description"><span className="font-weight-bold">{bold1}</span>{list1}</p></li>
                                            <li className="pb-2"><p className="description"><span className="font-weight-bold">{bold2}</span>{list2}</p></li>
                                            <li className="pb-2"><p className="description"><span className="font-weight-bold">{bold3}</span>{list3}</p></li>
                                            <li className="pb-2"><p className="description"><span className="font-weight-bold">{bold4}</span>{list4}</p></li>
                                        </ul>
                                        {/* <div className="purchase-btn">
                                            <Link className="btn-transparent" to="/">PURCHASE IMROZ</Link>
                                        </div> */}
                                    </div>
                                    <img className="w-100" className="mt-4" src="/assets/images/bg/bg-image-17.jpg" alt="About Images" />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;