import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FiX, FiMenu } from "react-icons/fi";


class Header extends Component {
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function () {
            console.log('All assets are loaded')
        })
    }

    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }
    render() {


        var elements = document.querySelectorAll('.has-droupdown > a');
        for (var i in elements) {
            if (elements.hasOwnProperty(i)) {
                elements[i].onclick = function () {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }

        const { logo, color = 'default-color' } = this.props;
        let logoUrl;
        if (logo === 'light') {
            logoUrl = <img src="/assets/images/logo/logo-light.png" alt="Digital Agency" />;
        } else if (logo === 'dark') {
            logoUrl = <img src="/assets/images/logo/logo-dark.png" alt="Digital Agency" />;
        } else if (logo === 'symbol-dark') {
            logoUrl = <img src="/assets/images/logo/logo-symbol-dark.png" alt="Digital Agency" />;
        } else if (logo === 'symbol-light') {
            logoUrl = <img src="/assets/images/logo/logo-symbol-light.png" alt="Digital Agency" />;
        } else {
            logoUrl = <img src="/assets/images/logo/logo.png" alt="Digital Agency" />;
        }

        return (
            <header className={`header-area header--fixed formobile-menu header--transparent ${color}`}>
                <div className="header-wrapper" id="header-wrapper">
                    <div className="header-left">
                        <div className="logo">
                            <a href="/">
                                {logoUrl}
                            </a>
                        </div>
                    </div>
                    <div className="header-right">
                        <nav className="mainmenunav d-lg-block">
                            <ul className="mainmenu">
                                <li className="has-droupdown"><Link to="/" >Home</Link>
                                    <ul className="submenu">
                                        <li><Link to="/about">About</Link></li>
                                        <li><Link to="/meetthecommunity">Meet the Community</Link></li>
                                    </ul>
                                </li>


                                <li className="has-droupdown"><Link to="#pages" >Wiki</Link>
                                    <ul className="submenu">
                                        <li><Link to="/virtualrepository">Virtual Repository</Link></li>
                                        <li><Link to="/survey">Survey Data</Link></li>
                                        <li><Link to="/reference-metadata">Reference Metadata</Link></li>
                                        <li><Link to="/dataplatforms">Data Platform</Link></li>
                                    </ul>
                                </li>
                                {/* <li className="has-droupdown"><Link to="/service" >Service</Link>
                                    <ul className="submenu">
                                        <li><Link to="/service">Service</Link></li>
                                        <li><Link to="/service-details">Service Details</Link></li>
                                    </ul>
                                </li> */}

                                {/* <li className="has-droupdown"><Link to="#pages" >Pages</Link>
                                    <ul className="submenu">
                                        <li><Link to="/blog">Blog List</Link></li>
                                        <li><Link to="/blog-details">Blog Details</Link></li>
                                        <li><Link to="/service">Service</Link></li>
                                        <li><Link to="/service-details">Service Details</Link></li>
                                        <li><Link to="/portfolio">Portfolio</Link></li>
                                        <li><Link to="/portfolio-details">Portfolio Details</Link></li>
                                        <li><Link to="/404">404</Link></li>
                                    </ul>
                                </li> */}


                                <li className="has-droupdown"><Link to="#" >Activities</Link>
                                    <ul className="submenu">
                                        <li><Link to="sprint-projects">Sprint Projects</Link></li>
                                        <li><Link to="/rnd-programs">R&D Programs</Link></li>
                                        <li><Link to="/workshops">Workshops</Link></li>
                                    </ul>
                                </li>

                                <li className="has-droupdown"><Link to="#" >Updates</Link>
                                    <ul className="submenu">
                                        <li><Link to="/news">News Updates</Link></li>
                                        <li><Link to="/synergetic-activities">Synergetic Activities</Link></li>
                                    </ul>
                                </li>

                                <li className="has-droupdown"><Link to="#" >Media</Link>
                                    <ul className="submenu">
                                        <li><Link to="/photosgallery">Photo gallery</Link></li>
                                        <li><Link to="/videos">Videos</Link></li>
                                        <li><Link to="/podcasts">Podcasts</Link></li>
                                    </ul>
                                </li>

                                <li><Link to="/contact" >Contact</Link></li>
                            </ul>
                        </nav>
                        <div className="header-btn">
                            <a className="btn-default btn-border btn-opacity" target="_blank" href="https://dashboard.miheatingcoolingwiki.com/">
                                <span>Register/Login</span>
                            </a>
                        </div>
                        {/* Start Humberger Menu  */}
                        <div className="humberger-menu d-block d-lg-none pl--20 pl_sm--10">
                            <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                        </div>
                        {/* End Humberger Menu  */}
                        <div className="close-menu d-block d-lg-none">
                            <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                        </div>
                    </div>
                </div>

            </header>
        )
    }
}
export default Header;