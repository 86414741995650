import React, { Component } from "react";
import { FiSend } from "react-icons/fi";

import { Link } from 'react-router-dom';
class About extends Component {
    render() {
        let title = 'Activities',
            list1 = 'Defining priorities for innovation around technologies, systems, commercial/ business models, human interaction and considering connectivity to the wider energy system.',
            list2 = 'Identifying specific innovation needs (e.g. through deep-dive workshops, reports, and innovation roadmaps).',
            list3 = 'Building on previous IC7 work to deliver impact across the international heating and cooling space:E.g. ‘Comfort Climate Box for Cooling’ (CCB2), E.g. Sustainable cooling roadmaps for cities (with GCoM and UNEP)',
            list4 = 'Facilitating new research collaborations and connecting with other organizations to leverage additional benefits (e.g. Race to Zero, the Cool Coalition, Horizon Europe European Partnerships, Positive Energy Districts, etc).',
            list5 = 'Supporting innovation projects within MI Missions and support the development of National Cooling Action Plans.',
            list6 = 'Providing a repository with live library/database/information on state-of-the-art innovation topics.',
            list7 = 'Convening the innovation community to share research findings, national RD&D priorities, lessons learned and best practices (e.g. at workshops, forums, webinars).',
            list8 = 'Disseminating the challenges and opportunities of low carbon heating and cooling to a wider audience.';

        return (
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-12 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <div className="icon">
                                            <FiSend />
                                        </div>
                                        <h2 className="title">{title}</h2>
                                        <ul>
                                            <li className="pb-2"><p className="description">{list1}</p></li>
                                            <li className="pb-2"><p className="description">{list2}</p></li>
                                            <li className="pb-2"><p className="description">{list3}</p></li>
                                            <li className="pb-2"><p className="description">{list4}</p></li>
                                            <li className="pb-2"><p className="description">{list5}</p></li>
                                            <li className="pb-2"><p className="description">{list6}</p></li>
                                            <li className="pb-2"><p className="description">{list7}</p></li>
                                            <li className="pb-2"><p className="description">{list8}</p></li>
                                        </ul>
                                        {/* <div className="purchase-btn">
                                            <Link className="btn-transparent" to="/">PURCHASE IMROZ</Link>
                                        </div> */}
                                    </div>

                                </div>
                            </div>
                            {/* <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about-3.jpg" alt="About Images" />
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;