import React, { Component } from "react";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import axios from 'axios'
import { API } from "../backend";

let TeamContent = [
    {
        images: '01',
        title: 'Piero De Bonis',
        designation: 'European Commission',
        country: 'Belgium',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaLinkedinIn />,
                url: '#'
            },
            {
                icon: <FaTwitter />,
                url: '#'
            },
        ]
    },
    {
        images: '02',
        title: 'Dr. Graeme Maidment',
        designation: 'Department for Business, Energy & Industrial Strategy',
        country: 'UK',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaLinkedinIn />,
                url: '#'
            },
        ]
    },
    {
        images: '04',
        title: 'Dr.JBV Reddy',
        designation: 'Department of Science & Technology',
        country: 'India',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaLinkedinIn />,
                url: '#'
            },
            {
                icon: <FaTwitter />,
                url: '#'
            },
        ]
    },
    {
        images: '05',
        title: 'Jon Saltmarsh',
        designation: 'Department for Business, Energy & Industrial Strategy',
        country: 'UK',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaLinkedinIn />,
                url: '#'
            },
            {
                icon: <FaTwitter />,
                url: '#'
            },
        ]
    },
    {
        images: '07',
        title: 'Benjamin Hickman',
        designation: 'United Nations Environment Programme',
        country: 'France',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaLinkedinIn />,
                url: '#'
            },
        ]
    },
    {
        images: '08',
        title: 'Dr.Iain Campbell',
        designation: 'Rocky Mountain Institute',
        country: 'US',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaLinkedinIn />,
                url: '#'
            },
            {
                icon: <FaTwitter />,
                url: '#'
            },
        ]
    },
    {
        images: '09',
        title: 'Teun Bokhoven',
        designation: 'Energy storage TCP',
        country: 'Netherlands',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaLinkedinIn />,
                url: '#'
            },
            {
                icon: <FaTwitter />,
                url: '#'
            },
        ]
    },
    {
        images: '10',
        title: 'Monica Axell',
        designation: 'Heat Pump Technology TCP',
        country: 'Sweden',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaLinkedinIn />,
                url: '#'
            },
            {
                icon: <FaTwitter />,
                url: '#'
            },
        ]
    },
    {
        images: '11',
        title: 'Dr. Stephen White',
        designation: 'The Commonwealth Scientific and Industrial Research Organisation',
        country: 'Australia',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaLinkedinIn />,
                url: '#'
            },
            {
                icon: <FaTwitter />,
                url: '#'
            },
        ]
    },
    {
        images: '12',
        title: 'Emina Pasic',
        designation: 'Swedish Energy Agency',
        country: 'Sweden',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaLinkedinIn />,
                url: '#'
            },
            {
                icon: <FaTwitter />,
                url: '#'
            },
        ]
    },
    {
        images: '13',
        title: 'Amal Hamadeh',
        designation: 'MOEI',
        country: 'UAE',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaLinkedinIn />,
                url: '#'
            },
            {
                icon: <FaTwitter />,
                url: '#'
            },
        ]
    },
];


class Team extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
        }

    }


    componentDidMount = () => {
        this.fetchuser({ is_active: true })
    }

    fetchuser = (params = {}) => {
        axios
            .get(`${API}/usershow`, {
                params: {
                    ...params,
                },
                type: 'json',
            })
            .then(res => {
                this.setState({
                    users: res.data.results,
                })
            })
    }


    render() {
        const { column } = this.props;
        return (
            <React.Fragment>
                {this.state.users.map((value, i) => (
                    <div className={`${column}`} key={i}>
                        <div className="team">
                            <div className="thumbnail">
                                {value.image ? (<div
                                    style={{
                                        backgroundImage: `url(${value.image})`,
                                    }}
                                    className="userimg"
                                ></div>) : (<div
                                    style={{
                                        backgroundImage: `url(/assets/images/team/userimage.png)`,
                                    }}
                                    className="userimg"
                                ></div>)}

                                {/* <img src={`/assets/images/team/team-${value.images}.jpg`} alt="Blog Images" /> */}
                            </div>
                            <div className="content">
                                <h4 className="title" style={{ fontSize: 10 }}>{value.full_name}</h4>
                                <p className="designation mb-0" style={{ fontSize: 8, lineHeight: 1.5 }}>{value.affliation},</p>
                                <p className="designation mt-0" style={{ fontSize: 8 }}>{value.country.country_name}</p>
                            </div>
                            {/* <ul className="social-icon" >
                                {value.socialNetwork.map((social, index) =>
                                    <li key={index}><a href={`${social.url}`}>{social.icon}</a></li>
                                )}
                            </ul> */}
                        </div>
                    </div>
                ))}
            </React.Fragment>
        )
    }
}
export default Team;