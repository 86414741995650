import React, { Component } from "react";
import axios from 'axios'
import { API } from "../../../backend";

class BlogDetails extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false,
            testbeds: [],
            organization: [],
            images: [],
            country: [],
            relevant_thematic_areas: [],
            funding_program: [],
            links: [],
            purpose: [],
        }
        this.openModal = this.openModal.bind(this)
    }

    componentDidMount = () => {
        this.fetchtestbeds({ status: true })

    }

    fetchtestbeds = (params = {}) => {
        axios
            .get(`${API}/test-bed-web?id=${this.props.Id}`, {
                params: {
                    ...params,
                },
                type: 'json',
            })
            .then(res => {
                this.setState({
                    testbeds: res.data.results[0],
                    images: res.data.results[0].images,
                    relevant_thematic_areas: res.data.results[0].relevant_thematic_areas,
                    links: JSON.parse(res.data.results[0].links),
                    purpose: JSON.parse(res.data.results[0].purpose),
                })
            })
    }

    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        console.log('testbeds', this.state.testbeds)
        return (
            <React.Fragment>
                {/* Start Blog Details */}
                <div className="rn-blog-details pt--10 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                        <div className=" text-center">
                                            <h2 className="title theme-gradient">{this.state.testbeds.test_bed_name}</h2>
                                        </div>
                                        <div className="blog-single-list-wrapper row ml-5">
                                            <div className="thumbnail col-8">
                                                {this.state.images.length > 0 ? <img className="w-100" src={this.state.images[0].image} alt="Technology Images" /> : <img className="w-100" src='/assets/images/blog/default.png' alt="Technology Images" />}
                                                <span className="pl-0" style={{ fontSize: '16px' }}>{this.state.images[0]?.caption ? this.state.images[0]?.caption : this.state.testbeds.test_bed_name}</span>
                                            </div>
                                            <div className="content col-4 text-truncate">
                                                <h4 className="title mb-0">Purpose:</h4>
                                                <ul className="list-style">
                                                    {this.state.purpose.map((value) => (<li>{value?.description}</li>))}
                                                </ul>
                                                <h4 className="title mb-0">Year Developed:</h4>
                                                <ul className="list-style">
                                                    <li>{this.state.testbeds.year_developed}</li>
                                                </ul>
                                                <h4 className="title mb-0">Funding Programs:</h4>
                                                <ul className="list-style">
                                                    {this.state.funding_program.map((value) => (<li>{value?.funding_program_name}</li>))}
                                                </ul>
                                                <h4 className="title mb-0">Keywords:</h4>
                                                <ul className="list-style">
                                                    {this.state.relevant_thematic_areas.map((value) => (<li>{value.keyword}</li>))}
                                                </ul>
                                                <h4 className="title mb-0">Links:</h4>
                                                <ul className="list-style" >
                                                    {this.state.links.map((value) => { if (value.link) return <li><a href={value.link} style={{ color: '#61B8FE' }} target="_blank">{value.link}</a></li> })}
                                                </ul>
                                            </div>
                                        </div>

                                        {/* <p className="mt--25 mt_sm--5">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum. You need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend toitrrepeat predefined chunks. Necessary, making this the first true generator on the Internet. It re are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injectedeed eedhumour, or randomised words which don't look even slightly believable.</p>
                                        <div className="video-wrapper position-relative mb--40">
                                            <div className="thumbnail">
                                                <img src="/assets/images/blog/bl-big-01.jpg" alt="Blog Images" />
                                            </div>
                                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({ isOpen: false })} />
                                            <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button>
                                        </div>
                                        <p className="mb--0">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum. You need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend toitrrepeat predefined chunks. Necessary, making this the first true generator on the Internet. It re are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injectedeed eedhumour, or randomised words which don't look even slightly believable.</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}
            </React.Fragment>
        )
    }
}
export default BlogDetails;