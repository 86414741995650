import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import Breadcrumb from "../../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import FooterTwo from "../../component/footer/FooterTwo";
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
    InfoWindow,
} from 'react-google-maps';
import axios from 'axios'
import { API } from "../../backend";
import { compose, withProps, withStateHandlers } from 'recompose';
import { Card, CardBody } from "reactstrap";




const MapWithAMarkerClusterer = compose(
    withProps({
        googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyB0OGPbAYbeZcNp64D17mWdne_3Mx55DiM&v=3.exp&libraries=geometry,drawing,places",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `440px` }} />,
        mapElement: <div style={{ height: `100%` }} />,
    })
    , withStateHandlers(() => ({
        showInfoIndex: "",
    }), {
        onToggleOpen: () => (index) => ({
            showInfoIndex: index,
        })
    }),
    withScriptjs,
    withGoogleMap
)((props) =>
    <GoogleMap
        defaultZoom={2}
        defaultCenter={{ lat: 25.0391667, lng: 121.525 }}
    >
        {props.markersdata.map(marker => {
            return (
                <Marker
                    key={marker.id}
                    position={{ lat: marker.latitude, lng: marker.longitude }}
                    onClick={() => props.onToggleOpen(marker.id)}
                >
                    {(props.showInfoIndex === marker.id) && <InfoWindow onCloseClick={() => props.onToggleOpen("")}>
                        <div>
                            <h6 style={{ fontWeight: 'bold' }}>
                                {marker.technology_name}
                            </h6>
                            <h6>
                                {marker.map_description}
                            </h6>
                        </div>
                    </InfoWindow>}
                </Marker>
            )
        })}
    </GoogleMap>
);

class MapData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: '',
            keyword: '',
            relevant_thematic_areas: [],
            markers: []
        }
        this.onchangetype = this.onchangetype.bind(this);
        this.onchangekeyword = this.onchangekeyword.bind(this);

    }

    componentDidMount = () => {
        this.fetchrelevant_thematic_areas()
    }

    onchangetype(event) {
        this.setState({
            type: event.target.value
        });
    }

    onchangekeyword(event) {
        this.setState({
            keyword: event.target.value
        });
    }

    fetchrelevant_thematic_areas = (params = {}) => {
        axios.get(`${API}/relevant-thematic-areas`, {}).then(res => {
            this.setState({
                relevant_thematic_areas: res.data.results,
            })
        })
    }

    onSearchSubmit = async (event) => {
        event.preventDefault()
        axios
            .get(`${API}/get-map-data?field=${this.state.type}&keyword=${this.state.keyword}`, {})
            .then(res => {
                this.setState({
                    markers: res.data.result,
                })
            })
    }


    render() {
        const keywordvalue = this.state.relevant_thematic_areas.map(item => (
            <option key={item.id} value={item.id}>
                {item.keyword}
            </option>
        ))


        return (
            <React.Fragment>
                <PageHelmet pageTitle='Map Data' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Map Data'} />
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--20 bg_color--3">
                    <div className="container my-0">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row row--35 sercice-details-content align-items-center">
                                            <div className="col-lg-12 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <div className="section-title text-center">
                                                        {/* <img className="my-3" width="10%" src="/assets/images/logo/speaker.png" alt="Service Images" />
                                                        <h2 className="title">Map Data!!</h2> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-12">
                                                <Card>
                                                    <CardBody>
                                                        <div className="form-wrapper">
                                                            <h4 className="text-center mb-0">Search Technology in Map</h4>
                                                            <form action="" onSubmit={this.onSearchSubmit} className="row">
                                                                <div className="rn-form-group my-4 col-6">
                                                                    <label className="mb-10 pl-1" htmlFor="type" style={{ fontSize: '16px' }}>Select Type:</label>
                                                                    <select name="type" placeholder="Select Type" id="type" className="form-control" onChange={this.onchangetype}>
                                                                        <option key={0} value={0}>Select</option>
                                                                        <option key="hvac" value="hvac">HVAC</option>
                                                                        <option key="policies" value="policies">Polices</option>
                                                                        <option key="testbed" value="testbed">R&D Test Beds</option>
                                                                        <option key="researchfacility" value="researchfacility"> Research Facilities</option>
                                                                        <option key="randd" value="randd"> R&D Program</option>
                                                                    </select>
                                                                </div>


                                                                <div className="rn-form-group my-4 col-6">
                                                                    <label className="mb-10 pl-1" htmlFor="country" style={{ fontSize: '16px' }}>Keywords:</label>
                                                                    <select name="keyword" placeholder="Select Keyword" id="keyword" className="form-control" onChange={this.onchangekeyword}>
                                                                        <option key={0} value={0}>Select</option>
                                                                        {keywordvalue}
                                                                    </select>

                                                                </div>

                                                                <div className="rn-form-group text-center col-12" >
                                                                    <button className="btn-default" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">Search</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                <MapWithAMarkerClusterer markersdata={this.state.markers} />

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <FooterTwo />

            </React.Fragment>
        )
    }
}
export default MapData