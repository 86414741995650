import React from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import { Card, CardBody, CardTitle, Row, Col, Alert } from 'reactstrap';
import axios from 'axios'
import { API } from "../../backend";
import { Link } from 'react-router-dom';
import PageHelmet from "../../component/common/Helmet";
import Breadcrumb from "../../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import FooterTwo from "../../component/footer/FooterTwo";

const data = {
    labels: [
        'Red',
        'Blue',
        'Yellow',
        'Green',
        'Orange'
    ],

    datasets: [{
        data: [400, 50, 100, 80, 150],
        backgroundColor: [
            '#FF6384',
            '#36A2EB',
            '#FFCE56',
            '#00cc99',
            '#ffa31a'
        ],
        hoverBackgroundColor: [
            '#FF6384',
            '#36A2EB',
            '#FFCE56',
            '#00cc99',
            '#ffa31a'
        ]
    }]
};

const stackedData = {
    labels: ["Organic", "Sponsored", "Organic", "Sponsored"],
    previousDate: {
        label: "08/10/2019 - 09/30/2019",
        dataSet: [10000, 150000, 10000, 150000]
    },
    currentDate: {
        label: "10/01/2019 - 11/20/2019",
        dataSet: [10000, 225000, 10000, 225000]
    }
};


var rFactor = function () {
    return Math.round(Math.random() * 100);
};
var barData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [{
        label: 'Apple',
        backgroundColor: '#36a2eb',
        borderColor: '#36a2eb',
        data: [rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor()]
    },
    {
        label: 'Google',
        backgroundColor: '#FF6384',
        borderColor: '#FF6384',
        data: [rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor()]
    }],
};
var lineData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [{
        label: 'Income',
        backgroundColor: 'rgb(56, 182, 202)',
        borderColor: 'rgb(56, 182, 202)',
        pointBorderColor: '#fff',
        data: [rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor()]
    }, {
        label: 'Outcome',
        backgroundColor: 'rgb(249, 249, 249)',
        borderColor: 'rgb(249, 249, 249)',
        pointBorderColor: '#fff',
        data: [rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor(), rFactor()]
    }]
};


const LinechartState = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
        {

            lineTension: 0.1,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: 'rgba(75,192,192,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: [65, 59, 80, 81, 56, 55, 40]
        }
    ]
};

const BarchartState = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
        {
            label: 'January',
            backgroundColor: 'rgba(255,99,132,0.2)',
            borderColor: 'rgba(255,99,132,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(255,99,132,0.4)',
            hoverBorderColor: 'rgba(255,99,132,1)',
            data: [65, 59, 80, 81, 56, 55, 40]
        }
    ]
};

const colors = ['#052F59', '#0085ea', '#62a7ff', '#8ab8ff', '#abc9ff', '#c9dbff', '#e4edff',
    '#384d73', '#5f6d8d', '#868fa9', '#adb3c5', '#63B5FE', '#82C3FE', '#A1D2FE', '#C0E1FE', '#DFF0FE', '#EFF7FE'];

class SurveyData extends React.Component {

    constructor(props) {
        super(props);
        this.dropdownbarOpen = this.dropdownbarOpen.bind(this);
        this.dropdownlineOpen = this.dropdownlineOpen.bind(this);
        this.tabsclick = this.tabsclick.bind(this);
        this.addevent = this.addevent.bind(this);
        this.state = {
            modal: false,
            survey1data: [],
            survey2data: [],
            calendarEvents: [
                {
                    title: "Wordcamps",
                    start: new Date("2019-11-17 00:00"),
                    id: "99999998"
                },
                {
                    title: "Apple Event",
                    start: new Date("2019-11-26 00:00"),
                    id: "99999999"
                }
            ],
            dropdownbarOpen: false,
            dropdownlineOpen: false,
            activeTab: '1',
            widths: 150
        }

    }


    componentDidMount = () => {
        this.fetchsurvey1()
        this.fetchsurvey2()
    }

    fetchsurvey1 = () => {
        axios.get(`${API}/surveybarchart?survey_report_id=1`).then(res => {
            var arr = []
            var arr1 = {
                labels: res.data.heading,
                datasets: [{
                    label: "Country's and Organization's Priorities",
                    backgroundColor: '#36a2eb',
                    borderColor: '#36a2eb',
                    data: res.data.value
                }],
            };
            arr.push(arr1)
            this.setState({
                survey1data: arr,
            })
        })
    }


    fetchsurvey2 = () => {
        axios.get(`${API}/surveybarchart?survey_report_id=2`).then(res => {
            var arr = []
            var arr1 = {
                labels: res.data.heading,
                datasets: [{
                    label: "Relevent Topics of Interest",
                    backgroundColor: '#9B81FD',
                    barThickness: 15,
                    borderColor: '#9B81FD',
                    data: res.data.value
                }],
            };
            arr.push(arr1)
            this.setState({
                survey2data: arr,
            })
        })
    }

    addevent() {
        this.setState({
            modal: !this.state.modal
        });
    }

    eventClick = eventClick => {
        Alert.fire({
            title: eventClick.event.title,
            html:
                `<div class="table-responsive">
          <table class="table">
          <tbody>
          <tr >
          <td>Title</td>
          <td><strong>` +
                eventClick.event.title +
                `</strong></td>
          </tr>
          <tr >
          <td>Start Time</td>
          <td><strong>
          ` +
                eventClick.event.start +
                `
          </strong></td>
          </tr>
          </tbody>
          </table>
          </div>`,

            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Remove Event",
            cancelButtonText: "Close"
        }).then(result => {
            if (result.value) {
                eventClick.event.remove(); // It will remove event from the calendar
                Alert.fire("Deleted!", "Your Event has been deleted.", "success");
            }
        });
    };
    dropdownbarOpen() {
        this.setState(prevState => ({
            dropdownbarOpen: !prevState.dropdownbarOpen

        }));
    }
    dropdownlineOpen() {
        this.setState(prevState => ({

            dropdownlineOpen: !prevState.dropdownlineOpen
        }));
    }
    tabsclick(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    componentWillMount() {
        this.setState(LinechartState);
        this.setState(BarchartState);
    }

    render() {
        console.log(this.state.survey1data, 'Data')
        const colors = ['#052F59', '#0085ea', '##60B7FE', '#8ab8ff', '#abc9ff', '#c9dbff', '#e4edff',
            '#384d73', '#5f6d8d', '#868fa9', '#adb3c5', '#63B5FE', '#82C3FE', '#A1D2FE', '#C0E1FE', '#DFF0FE', '#EFF7FE'];
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Survey Data' />
                <Breadcrumb title={'Survey Data'} />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                <div className="rn-service-details ptb--20 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                {/* <!-- widgets --> */}
                                {/* Start Page Wrapper */}
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row row--35 sercice-details-content align-items-center">
                                            <div className="col-lg-12 col-12">
                                                <div className="details mt_md--30 mt_sm--30 mt-5">
                                                    <div className="section-title">
                                                        <h3 className="title">MI Innovation Community Priority area global survey 2021:</h3>
                                                        <p className="description">A global survey was administered with member countries, active members and other organisations to find innovation priorities in heating and cooling of buildings. </p>
                                                        <img className="w-100 my-3" src="/assets/images/bg/bg-image-18.jpg" alt="Service Images" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                                {/* End Page Wrapper */}
                                <Row>
                                    <Col xl={12} className="mb-5 mt-4">
                                        <Card className="card-statistics h-100">
                                            <CardBody>
                                                <div className="d-block text-center">
                                                    <CardTitle className="font-weight-bold">Country's and Organization's Priorities vs Number</CardTitle>
                                                </div>

                                                <div className="chart-wrapper" style={{ height: 350 }}>
                                                    <HorizontalBar data={this.state.survey1data[0]} width={this.state.widths} options={{ maintainAspectRatio: false, legend: { display: true, labels: { fontFamily: "Poppins" } }, scales: { yAxes: [{ gridLines: { display: false }, ticks: { fontFamily: "Poppins" } }], xAxes: [{ gridLines: { display: false }, ticks: { fontFamily: "Poppins" } }] } }} className="scrollbar-x text-center" />
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col xl={12} className="mb-5">
                                        <Card className="card-statistics h-100">
                                            <CardBody>
                                                <div className="d-block text-center">
                                                    <CardTitle className="font-weight-bold">Relevent Topics of Interest vs Number</CardTitle>
                                                </div>

                                                <div className="chart-wrapper" style={{ height: 350 }}>
                                                    <HorizontalBar data={this.state.survey2data[0]} width={this.state.widths} options={{ maintainAspectRatio: false, legend: { display: true, labels: { fontFamily: "Poppins" } }, scales: { yAxes: [{ gridLines: { display: false }, ticks: { fontFamily: "Poppins" } }], xAxes: [{ gridLines: { display: false }, ticks: { fontFamily: "Poppins", beginAtZero: true } }] } }} className="scrollbar-x text-center" />
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <FooterTwo />

            </React.Fragment>



        );
    }
}
export default SurveyData;